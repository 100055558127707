import AppContainer from "../components/AppContainer";
import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";

// import banner1 from '../assets/banners/Banner_Slide-3.jpg';
// import banner2 from '../assets/banners/Banner_Slide-1.jpg';
// import banner3 from '../assets/banners/Banner_Slide-2.jpg';
import banner4 from '../assets/banners/banner1.jpg';

import aboutUsImages from "../misc/AboutUsImages";
import partnerImages from "../misc/PartnerImages";
import sponsorLogo from "../assets/temp_banner/frankfurterbookmese.png"
import Palette from "../utils/Palette";
import SpeakerCard from "../components/SpeakerCard";
import Slider from "react-slick";
import {FiArrowRight} from "react-icons/fi";
import SpeakerDAO from "../dao/SpeakerDAO";
import LoadingProgress from "../components/LoadingProgress";
import AgendaComponentV2 from "../components/AgendaComponentV2";
import SectionHeading from "../components/SectionHeading";

import CircularButton from "../components/CircularButton"
import CuratorDAO from "../dao/CuratorDAO";

export default function Home() {

    const banners = [banner4]

    const [mainCarouselSettings, setMainCarouselSettings] = useState({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false
    })

    const [speakerCarouselSettings, setSpeakerCarouselSettings] = useState({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    });

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [speakers, setSpeakers] = useState([]);
    const [curators, setCurators] = useState([]);
    const [isSpeakerDataFetched, setIsSpeakerDataFetched] = useState(false);

    const getSpeakers = async () => {
        try {
            let result = await SpeakerDAO.getAll();
            await setSpeakers(result);
            setIsSpeakerDataFetched(true);
        } catch (e) {
            console.log(e);
        }
    }

    const getCurators = async () => {
        try {
            let result = await CuratorDAO.getAll();
            console.log(result)
            await setCurators(result);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getSpeakers();
        getCurators();
    }, []);

    useEffect(() => {
        const carouselSettingsTemp = {...speakerCarouselSettings};

        if (windowWidth < 768) {
            carouselSettingsTemp.slidesToShow = 1
        } else if (windowWidth < 992) {
            carouselSettingsTemp.slidesToShow = 2
        } else {
            carouselSettingsTemp.slidesToShow = 4
        }

        setSpeakerCarouselSettings(carouselSettingsTemp)
    }, [windowWidth])

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const renderCarouselSection = () => {
        let settings = {
            ...mainCarouselSettings,
            swipe: false
        }
        return (
            <Slider{...settings}>
                {
                    banners.map((obj, key) => {
                        return <Col
                            style={{
                                width: "100%",
                                padding: 0,
                                backgroundColor: "#f3e8e1"
                                // paddingTop: 150
                            }}
                            md={12}>
                            <img
                                style={{
                                    width: "100%",
                                    objectFit: "contain"
                                }}
                                src={obj}/>
                        </Col>
                    })
                }
            </Slider>
        )

    }
    const renderAboutUsSection = () => {
        const carouselSettings = {
            ...mainCarouselSettings,
            dots: false,
        }
        return <Col md={12} style={{marginTop: 50, marginBottom: 50, paddingTop: 25, paddingBottom: 25}}>
            <Container>
                <SectionHeading windowWidth={windowWidth}>About Us</SectionHeading>
                <Row>
                    <Col lg={1} sm={12}></Col>
                    <Col
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center"
                        }}
                        lg={5} sm={12}>
                        <div style={{
                            fontSize: "2em"
                        }}>
                            Welcome to Jaktent!
                        </div>
                        <div>
                            <br/>
                            Jakarta Content Week (Jaktent) is an international creative festival pioneered
                            and organized by Yayasan Tujuhbelasribu Pulau Imaji (17000 Islands of Imagination
                            Foundation) in collaboration with Frankfurter Buchmesse (FBF). Jaktent brings together
                            major players from various creative industries sectors - publishing, cuisine, fashion,
                            film, design, games, crafts, cosplay, digital startups, and other media.
                        </div>

                        <CircularButton
                            style={{width: 100}}
                            isLink
                            to={'/about'}
                        >
                            MORE
                        </CircularButton>
                    </Col>
                    <Col lg={5} sm={12} style={{marginTop: 10, marginBottom: 10}}>
                        <Slider {...carouselSettings} style={{alignItems: "center"}}>
                            {aboutUsImages.map(photo => {
                                return <img src={photo}/>
                            })}
                        </Slider>
                    </Col>
                    <Col lg={1} sm={12}></Col>
                </Row>
            </Container>
        </Col>
    }
    const renderSpeakerSection = () => {

        let speakerColors = [Palette.PINK, Palette.GREEN, Palette.ORANGE, Palette.RED]

        return <Col md={12} style={{padding: 0, marginTop: 50, marginBottom: 50, paddingTop: 25, paddingBottom: 25}}>
            <SectionHeading windowWidth={windowWidth}>Speakers</SectionHeading>
            {isSpeakerDataFetched ?
                <>
                    <Slider {...speakerCarouselSettings}>
                        {
                            speakers.map((obj, key) => {
                                return <SpeakerCard
                                    speaker={obj}
                                    noPadding
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    lg={3}
                                    xl={2}
                                    isMobile={windowWidth < 768}
                                    height="500px"
                                    activeColor={speakerColors[key % speakerColors.length] + "90"}
                                />
                            })
                        }
                    </Slider>


                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <CircularButton
                            isLink
                            to={'/speakers'}
                            style={{display: "flex", flexDirection: "row"}}
                        >
                            ALL SPEAKERS
                            <FiArrowRight style={{marginLeft: 6}} size={20}/>
                        </CircularButton>
                    </div>
                </> :
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: 40, marginTop: 40}}>
                    <LoadingProgress/>
                </div>
            }
        </Col>
    }
    const renderCuratorSection = () => {
        let carouselSettings = {
            ...speakerCarouselSettings,
            // adaptiveHeight: true
        }

        return <Col md={12} style={{padding: 0, marginTop: 50, marginBottom: 50, paddingTop: 25, paddingBottom: 25}}>
            <SectionHeading windowWidth={windowWidth}>Curators</SectionHeading>
                <Slider {...carouselSettings}>
                    {curators.map(obj => {
                        return (
                            // <div>
                            //     <div style={{
                            //         height: "400px",
                            //         backgroundImage: `url(${curator.image})`,
                            //         backgroundSize: "cover",
                            //         "-moz-filter": `url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale")`,
                            //         "-o-filter": `url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale")`,
                            //         "-webkit-filter": `grayscale(100%)`,
                            //     }}>
                            //     </div>
                            // </div>
                            <Col style={{
                                textAlign: "center",
                                marginTop: "0.5em",
                                marginBottom: "0.5em"
                            }} lg={4} md={6}>

                                <div style={{
                                    background: "white",
                                    paddingTop: 50,
                                    paddingBottom: 50,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    paddingLeft: "10%",
                                    paddingRight: "10%"
                                }}>

                                    <img
                                        style={{
                                            maxWidth: 200,
                                            aspectRatio: 1,
                                            borderRadius: 250,
                                            objectFit: "cover",
                                            objectPosition: "center"
                                        }}
                                        src={obj.image}
                                    />

                                    <div style={{
                                        fontWeight: "bold",
                                        fontSize: "2em",
                                        marginTop: 30,
                                        textAlign: "center"
                                    }}>
                                        {obj.name}
                                    </div>
                                    <div style={{
                                        fontSize: "1em",
                                    }}>
                                        {obj.affiliation}
                                    </div>

                                    <p style={{
                                        color: "gray",
                                        textAlign: "center"
                                    }}>
                                        <br/>
                                        {obj.bio}
                                    </p>

                                </div>
                            </Col>
                        )
                    })}
                </Slider>


                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <CircularButton
                        isLink
                        to={'/curators'}
                        style={{display: "flex", flexDirection: "row"}}
                    >
                        ALL CURATORS
                        <FiArrowRight style={{marginLeft: 6}} size={20}/>
                    </CircularButton>
                </div>
        </Col>
    }
    const renderAgendaSection = () => {
        return <Col md={12} style={{marginTop: 50, marginBottom: 50, paddingTop: 25, paddingBottom: 25, padding : 0}}>
            <Container fluid>
                <SectionHeading windowWidth={windowWidth}>Agenda</SectionHeading>
                <Row>
                    <Col
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                        md={12}
                    >
                        <AgendaComponentV2/>
                    </Col>
                </Row>
            </Container>

        </Col>
    }
    const renderSponsorSection = () => {
        return <Col md={12} style={{marginTop: 50, marginBottom: 50, paddingTop: 25, paddingBottom: 25}}>
            <Container style={{maxWidth: 1200}}>
                <SectionHeading windowWidth={windowWidth}>Our Sponsors</SectionHeading>
                <Row style={{alignItems: "center", justifyContent: "center"}}>
                    <Col
                        style={{
                            padding: 10
                        }}
                        md={6}>
                        <img
                            style={{
                                objectFit: "contain",
                                width: "100%"
                            }}
                            src={sponsorLogo}/>
                    </Col>
                </Row>
            </Container>

        </Col>
    }
    const renderMediaPartnerSection = () => {
        return <Col md={12} style={{marginTop: 50, marginBottom: 50, paddingTop: 25, paddingBottom: 25}}>
            <Container style={{maxWidth: 1200}}>
                <SectionHeading windowWidth={windowWidth}>Media Partners</SectionHeading>
                <Row style={{alignItems: "center", justifyContent: "center"}}>
                    {partnerImages.map((obj) => {
                        return <Col
                            style={{
                                paddingTop: 20, paddingBottom: 20,
                                paddingLeft: 40, paddingRight: 40,
                            }}
                            md={3} xs={6}>
                            <img
                                style={{
                                    objectFit: "contain",
                                    width: "100%"
                                }}
                                src={obj}/>
                        </Col>
                    })}
                </Row>
            </Container>

        </Col>
    }

    return (
        <AppContainer removeTopPadding title={"Home"}>
            <Row style={{
                width: "100%",
                padding: 0,
                margin: 0,
            }}>
                {renderCarouselSection()}
                {renderAboutUsSection()}
                {renderSpeakerSection()}
                {renderCuratorSection()}
                {renderAgendaSection()}
                {/*{renderSponsorSection()}*/}
                {renderMediaPartnerSection()}
            </Row>
        </AppContainer>
    )
}
