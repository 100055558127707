import API from "../utils/API";

export default class SessionDAO {
    static getAll = async () => {
        return API.request('v1/sessions', 'GET');
    }

    static getById = async (id) => {
        return API.request('v1/session/'+id, 'GET');
    }

    static getOneBySpeakerId = async (id) => {
        return API.request(`v1/session/speaker/${id}`, 'GET');
    }

    static getAllBySelf = async () => {
        return API.request('v1/session/mysession', 'GET');
    }

    static getConflictingSessions = async (id) => {
        return API.request(`v1/session/mysession/conflict/${id}`, 'GET');
    }
}