import Palette from "../utils/Palette";
import {Scrollbars} from "react-custom-scrollbars";
import React from "react";

export default function CustomScrollbarTabbing({tabs, activePage, setActivePage, windowWidth, color, style}){

    return <Scrollbars
        autoHide
        universal
        hideTracksWhenNotNeeded={true}
        style={{width: '100%', ...style}} autoHeight renderThumbHorizontal={({ style, ...props }) =>
        <div {...props} style={{ ...style, backgroundColor: Palette.DARK_GRAY, opacity: .2, borderRadius: 500}}/>
    }>
        <div style={{display: 'flex'}}>
            {
                tabs.map((category, idx) => (
                    <div style={{display: 'flex'}}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginRight: 30
                        }}>

                            <div
                                onClick={() => {
                                    setActivePage(idx)
                                }}
                                style={{
                                    fontSize: windowWidth > 768 ? 30 : 20,
                                    fontFamily: "Anton",
                                    fontWeight: '700',
                                    userSelect: 'none',
                                    cursor: 'pointer',
                                    textTransform: 'uppercase',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    transition: 'font-size .5s',
                                    whiteSpace: 'nowrap',
                                    color : (color) ? color : Palette.DARK_GRAY
                                }}>
                                <div style={{fontWeight : 400, fontSize : "0.65em"}}>{category.subtitle}</div>
                                <div>{category.title}</div>
                            </div>
                            <div style={{
                                backgroundColor: idx===activePage ? Palette.ORANGE : Palette.LIGHT_GRAY,
                                height: 8,
                                width: '100%'
                            }}/>

                            {category.hovered || idx===activePage ? <div style={{
                                width: 0,
                                height: 0,
                                borderWidth: '10px 10px 0px 10px',
                                borderColor: `${Palette.ORANGE} transparent transparent transparent`,
                                borderStyle: 'solid',
                                marginBottom: 30
                            }}/> : null}

                            <div style={{
                                height : 20
                            }}>

                            </div>

                        </div>

                        {/*{idx !== tabs.length - 1 ? <div style={{*/}
                        {/*    height: 30,*/}
                        {/*    width: 1,*/}
                        {/*    backgroundColor: '#4f4f5f',*/}
                        {/*    marginTop: 15,*/}
                        {/*    marginRight: 30*/}
                        {/*}}/> : null}*/}
                    </div>
                ))
            }
        </div>
    </Scrollbars>
}