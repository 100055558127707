import AppContainer from "../components/AppContainer";
import React, {useEffect, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Scrollbars} from 'react-custom-scrollbars';
import {AiOutlineLeft, AiOutlineRight} from "react-icons/ai";
import Palette from "../utils/Palette";
import ScheduleCard from "../components/ScheduleCard";
import SessionDAO from "../dao/SessionDAO";
import _ from "lodash"
import CategoryDAO from "../dao/CategoryDAO";
import LoadingProgress from "../components/LoadingProgress";
import SessionsList from "../components/SessionsList";
import CategoryIcons from "../misc/CategoryIcons";

export default function Programs() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [categories, setCategories] = useState({});
    const [sessionsByCategory, setSessionsByCategory] = useState({});
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [activeCategory, setActiveCategory] = useState({})

    const [totalPage, setTotalPage] = useState(6);

    let currentCategory = categories[activeCategory]

    const getEventData=async()=>{
        try {
            let result1 = await CategoryDAO.getAll();
            result1[0].selected = true;
            setCategories(result1);
            setActiveCategory(result1[0]);

            let result2 = await SessionDAO.getAll()
            let spreadResult = []
            for (let r of result2) {
                for (let c of r.categories){
                    spreadResult.push({...r, category : c.name})
                }
            }

            let groupedByCategory = _.groupBy(spreadResult, "category")
            console.log(groupedByCategory)
            setSessionsByCategory(groupedByCategory)

            setIsDataLoading(false);
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(()=>{
        getEventData()
    },[])

    return (
        <AppContainer title={"Programs"}>
            <div style={{fontFamily: 'anton', fontSize: windowWidth > 768 ? 80: 40,  transition: 'font-size .5s', textAlign: 'center', marginTop: 50, marginBottom: 20}}>
                PROGRAMS
            </div>

            <Container fluid style={{textAlign: "center", backgroundColor: 'black', color: 'white', padding: 30, paddingLeft: windowWidth < 600 ? 0 : 30, paddingRight: windowWidth < 600 ? 0 : 30, marginBottom: 100}}>
                {isDataLoading ?
                    <LoadingProgress /> :
                    <Container>
                        <Scrollbars
                            style={{width: '100%'}}
                            autoHeight
                            renderThumbHorizontal={
                                ({style, ...props}) => <div {...props} style={{
                                    ...style,
                                    backgroundColor: 'white',
                                    opacity: .2,
                                    borderRadius: 500
                                }}/>
                            }
                            autoHide
                            universal={true}
                            hideTracksWhenNotNeeded={true}>
                            <div style={{display: 'flex'}}>
                                {categories.map((category, idx) => (
                                    <div style={{display: 'flex'}}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            marginRight: 30
                                        }}>
                                            <div
                                                onClick={async () => {
                                                    const categoriesTemp = [...categories];
                                                    categoriesTemp.forEach((_, idx2) => {
                                                        categoriesTemp[idx2].selected = false;
                                                    })
                                                    categoriesTemp[idx].selected = true;
                                                    setCategories(categoriesTemp)
                                                    await setActiveCategory(category)
                                                }}
                                                style={{
                                                    fontSize: windowWidth > 768 ? 30 : 20,
                                                    fontWeight: '700',
                                                    userSelect: 'none',
                                                    cursor: 'pointer',
                                                    textTransform: 'uppercase',
                                                    paddingLeft: 10,
                                                    paddingRight: 10,
                                                    transition: 'font-size .5s',
                                                    whiteSpace: 'nowrap'
                                                }}>
                                                {category.name}
                                            </div>
                                            <div style={{
                                                backgroundColor: category.selected ? Palette.SECONDARY : 'white',
                                                height: 4,
                                                width: '100%'
                                            }}/>

                                            {category.hovered || category.selected ? <div style={{
                                                width: 0,
                                                height: 0,
                                                borderWidth: '10px 10px 0px 10px',
                                                borderColor: '#F7C831 transparent transparent transparent',
                                                borderStyle: 'solid',
                                                marginBottom: 30
                                            }}/> : null}
                                        </div>

                                        {idx !== categories.length - 1 ? <div style={{
                                            height: 30,
                                            width: 1,
                                            backgroundColor: '#4f4f5f',
                                            marginTop: 15,
                                            marginRight: 30
                                        }}/> : null}
                                    </div>
                                ))}
                            </div>
                        </Scrollbars>

                        <div style={{
                            height: 'auto',
                            transition: 'opacity 3s ease',
                            padding: 15
                        }}>
                            <div style={{display: 'flex', marginBottom: 60, justifyContent: "center"}}>
                                {/*<img src={'icon_white_placeholder.png'}*/}
                                {/*     style={{*/}
                                {/*         maxWidth: windowWidth > 768 ? 25 : 20,*/}
                                {/*         transition: 'max-width .5s',*/}
                                {/*         objectFit: 'contain'*/}
                                {/*     }}/>*/}
                                {/*<div*/}
                                {/*    style={{*/}
                                {/*        fontWeight: '600',*/}
                                {/*        fontSize: windowWidth > 768 ? 30 : 20,*/}
                                {/*        transition: 'font-size .5s',*/}
                                {/*        marginLeft: 15*/}
                                {/*    }}>*/}
                                {/*    {activeCategory.name}*/}
                                {/*</div>*/}

                                {/*{CategoryIcons.renderBadge(windowWidth, activeCategory.name, 300)}*/}

                            </div>

                            {/*<div style={{*/}
                            {/*    marginTop: 15,*/}
                            {/*    marginBottom: 60,*/}
                            {/*    fontSize: windowWidth > 768 ? 16 : 14,*/}
                            {/*    transition: 'font-size .5s'*/}
                            {/*}}>*/}
                            {/*    {activeCategory.description}*/}
                            {/*</div>*/}

                            <SessionsList
                                sessions={sessionsByCategory[activeCategory.name]}
                                windowWidth={windowWidth}
                                errorMessage="No sessions found in this category." />
                        </div>
                    </Container>
                }
            </Container>

        </AppContainer>
    )
}
