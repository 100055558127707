import swal from "../components/CustomSweetAlert";

const timeout = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export default class API {

    static getToken = async () => {

        const getCookies = function () {
            let pairs = decodeURIComponent(document.cookie).split("; ");
            let cookies = {};
            for (let i = 0; i <pairs.length; i++){
                let pair = pairs[i].split("=");
                cookies[pair[0]] = pair[1];
            }
            return cookies;
        }

        let cookie = getCookies();

        // if (!cookie.token) {
        //     console.error("[ERR] Cookies/token not found. Redirecting to login in 2 seconds.");
        //     await timeout(500)
        //     return window.location.assign("/");
        // }

        return cookie.token;
    }

    static request = async (endpoint, method, body) => {

        let token = await this.getToken();

        let request = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: token ? `bearer ${token}` : null,
                Accept: 'application/json'
            },
            body: JSON.stringify(body)
        };


        let response = await fetch(process.env.REACT_APP_API_BASE_URL + endpoint, request);
        if (response.ok) {
            return await response.json();
        }

        let error = await response.json();
        if (
            error.code === 'JWT_EXPIRED' ||
            error.code === 'NO_SECRET_DEFINED' ||
            error.code === 'NO_TOKEN_PROVIDED' ||
            error.code === 'BAD_TOKEN_FORMAT' ||
            error.code === 'NO_USER_DATA' ||
            error.code === 'NO_MEMBER_DATA' ||
            error.code === 'NO_ADMIN_DATA' ||
            error.code === 'NO_SUPER_ADMIN_DATA'
        ) {
            // await swal.fire({
            //     title: 'Error',
            //     text: 'Session has expired. Please re-login.',
            //     icon: 'error',
            //     confirmButtonText: 'Ok'
            // })
            localStorage.removeItem('token');
            localStorage.removeItem('name');
            localStorage.removeItem('userId');
            return window.location.assign('/login');
        }

        throw error;

    }
}