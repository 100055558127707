import AppContainer from "../components/AppContainer";
import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import SpeakerCard from "../components/SpeakerCard";
import SpeakerDAO from "../dao/SpeakerDAO";
import LoadingProgress from "../components/LoadingProgress";
import Slider from "react-slick";
import speaker1 from "../assets/temp_banner/speaker/team_4.jpg";
import {FaInstagram, FaLinkedinIn} from "react-icons/fa";
import CuratorDAO from "../dao/CuratorDAO";
import _ from "lodash";
import Palette from "../utils/Palette";

export default function Curators() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [speakers, setSpeakers] = useState([]);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [curators, setCurators] = useState({});
    const [groups, setGroups] = useState([]);

    const getCurators = async () => {
        try {
            let result1 = await CuratorDAO.getAll();
            let result2 = await CuratorDAO.getGroups();
            result2 = [{
                id: 0, name: "null"
            }, ...result2]
            let groupedBy = _.groupBy(result1, (obj) => obj.affiliation)

            await setCurators(groupedBy);
            await setGroups(result2);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, []);

    useEffect(() => {
        getCurators();
    }, []);

    const renderCurator = (obj) => {
        return (
            <Col style={{
                textAlign: "center",
                marginTop: "0.5em",
                marginBottom: "0.5em"
            }} lg={6} md={6}
            >

                <div style={{
                    background: "white",
                    paddingTop: 50,
                    paddingBottom: 50,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingLeft: "10%",
                    paddingRight: "10%"
                }}
                >

                    <img
                        style={{
                            maxWidth: 200,
                            aspectRatio: 1,
                            borderRadius: 250,
                            objectFit: "cover",
                            objectPosition: "center"
                        }}
                        src={obj.image}
                    />

                    <div style={{
                        fontWeight: "bold",
                        fontSize: "2em",
                        marginTop: 30,
                    }}>
                        {obj.name}
                    </div>
                    <div style={{
                        fontSize: "1em",
                    }}>
                        {obj.affiliation}
                    </div>

                    <p style={{
                        color: "gray"
                    }}>
                        <br/>
                        {obj.bio}
                    </p>

                </div>
            </Col>
        )
    }

    return (
        <AppContainer title={"Curators"}>
            <Col
                style={{
                    fontFamily : "Anton",
                    textAlign : "center",
                    fontSize: windowWidth > 768 ? "6em" : '4em',
                    transition: 'font-size .5s',
                    marginTop : "0.5em",
                    marginBottom : "0.5em"
                }}
                md={12}>
                CURATORS
            </Col>

            <Container style={{
                maxWidth : 1400
            }}>
                {groups.map((group, key) => (<>
                    {group.name !== "null" &&
                        <Row style={{
                            width: "100%",
                            padding: 0,
                            margin: 0,
                            justifyContent: "center"
                        }}
                        >
                            <Col xs={12} sm={8} style={{display: "flex", alignItems: "center", justifyContent: "center", margin: "20px 0"}}>
                                <img
                                    style={{
                                        maxWidth: 150,
                                        aspectRatio: 1,
                                        borderRadius: 250,
                                        objectFit: "cover",
                                        objectPosition: "center"
                                    }}
                                    src={group.image}
                                />
                                <div style={{
                                    fontFamily : "Anton",
                                    textAlign : "center",
                                    fontSize: windowWidth > 768 ? "3em" : '2em',
                                    transition: 'font-size .5s',
                                    marginLeft: "1em"
                                }}>
                                    {group.name}
                                </div>
                            </Col>
                            <Col xs={12} sm={8} style={{margin: "20px 0", textAlign: "center"}}>
                                {group.bio}
                            </Col>
                        </Row>
                    }
                    <Row style={{
                        width: "100%",
                        padding: 0,
                        margin: 0,
                        marginBottom : "4em",
                        justifyContent: "center"
                    }}
                    >
                        {curators[group.name].map(obj => renderCurator(obj))}
                    </Row>
                    {key < groups.length - 1 &&
                        <div style={{height: 1, background: Palette.LIGHT_GRAY, marginTop: 20, marginBottom: 20, width: "100%"}}></div>
                    }
                </>))}
            </Container>
        </AppContainer>
    )
}
