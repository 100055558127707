import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import Palette from "../utils/Palette";

export default function SectionHeading({children, windowWidth, backgroundColor, textColor}) {
    return (
        <Row style={{
            marginBottom: windowWidth > 1000 ? "75px" : '25px',
        }}>
            <Container style={{
                padding: "10px 75px",
                width: "auto",
                textAlign: "center",
                fontSize: windowWidth > 1000 ? "3em" : '3em',
                transition: 'font-size .5s',
                fontFamily: "Interstate",
                fontWeight: "bold",
                backgroundColor: (backgroundColor) ? backgroundColor : "lightgray",
                color: (textColor) ? textColor : Palette.DARK_GRAY,
                borderRadius: 100,
            }}>
                {children}
            </Container>
        </Row>
    )
}