import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';

import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import {useEffect, useState} from "react";
import Home from "./views/Home";
import Speakers from "./views/Speakers";
import SpeakerDetail from "./views/SpeakerDetail";
import Programs from "./views/Programs";
import EventDetail from "./views/EventDetail";

import Login from "./views/Login";
import Register from "./views/Register";
import Agenda from "./views/Agenda";
import AboutUs from "./views/AboutUs";
import ComingSoon from "./views/ComingSoon";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
import MyEvents from "./views/MyEvents";
import EditProfile from "./views/EditProfile";
import Curators from "./views/Curators";
import Newsletter from "./views/Newsletter";
import PressRelease from "./views/PressRelease";

function App() {

    return (
        <Router>
            <Routes>
                <Route path='/home' element={<Home/>}/>
                <Route path='/programs' element={<Programs/>}/>
                <Route path='/speakers' element={<Speakers/>}/>
                <Route path='/curators' element={<Curators/>}/>
                <Route path='/speaker/:id' element={<SpeakerDetail/>}/>
                <Route path='/login' element={<Login/>}/>
                <Route path='/register' element={<Register/>}/>
                <Route path='/forgot-password' element={<ForgotPassword />}/>
                <Route path='/reset-password' element={<ResetPassword />}/>
                <Route path='/agenda' element={<Agenda/>}/>
                <Route path='/about' element={<AboutUs/>}/>
                <Route path='/about/newsletter' element={<Newsletter/>}/>
                <Route path='/about/press-release' element={<PressRelease/>}/>
                <Route path='/event/:id' element={<EventDetail/>}/>

                <Route path='/my-events' element={<MyEvents />}/>
                <Route path='/edit-profile' element={<EditProfile />}/>

                {/*<Route path='/my-events' element={isSessionSet ? <MyEvents /> : <Navigate to="/home" replace />}/>*/}
                {/*<Route path='/edit-profile' element={isSessionSet ? <EditProfile /> : <Navigate to="/home" replace />}/>*/}

                <Route path='/soon' element={<ComingSoon/>}/>

                <Route
                    path="*"
                    element={<Navigate to="/home" replace />}
                />
            </Routes>
        </Router>
    )
}

export default App;
