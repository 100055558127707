import React, {useEffect, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import ScheduleCard from "./ScheduleCard";
import {AiOutlineLeft, AiOutlineRight} from "react-icons/ai";
import Palette from "../utils/Palette";

export default function SessionsList(props) {

    const {sessions, windowWidth, errorMessage} = props;

    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(6);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        if (sessions === undefined) return;
        setPage(0);
        setPageCount(Math.ceil(sessions.length / itemsPerPage));
    }, [sessions])

    return <>
        {sessions === undefined ?
            <div style={{
                marginTop: 15,
                fontSize: windowWidth > 768 ? 16 : 14,
                transition: 'font-size .5s',
                textAlign: "center"
            }}>
                {errorMessage}
            </div> :
            <>
                <Row style={{marginTop: 15}}>
                    {sessions.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage).map(obj => {
                        return <ScheduleCard
                            session={obj}
                            windowWidth={windowWidth}
                        />
                    })}
                </Row>

                <Row>
                    <Col style={{
                        justifyContent: 'center',
                        marginTop: 30,
                        display: 'flex',
                        alignItems: 'center',
                        userSelect: 'none'
                    }}>
                        <Button
                            onClick={() => setPage(page - 1)}
                            variant={'outline-light'}
                            style={{borderWidth: 0, marginRight: 30, opacity: page === 0 ? 0 : 1}}
                            disabled={page === 0}
                            size={'sm'}>
                            <div>
                                <AiOutlineLeft size={15}/>
                            </div>
                        </Button>

                        <div style={{color: Palette.SECONDARY, marginRight: 5}}>Page {page + 1}</div>
                        of {pageCount}

                        <Button
                            onClick={() => setPage(page + 1)}
                            variant={'outline-light'} style={{
                            borderWidth: 0,
                            marginLeft: 30,
                            opacity: page === pageCount-1 ? 0 : 1
                        }}
                            disabled={page === pageCount-1}
                            size={'sm'}>
                            <div>
                                <AiOutlineRight size={15}/>
                            </div>
                        </Button>
                    </Col>

                </Row>
            </>
        }
    </>
}