import React, {useEffect, useState} from "react";
import Palette from "../utils/Palette";
import {Link} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import {FaCalendar, FaChevronCircleRight, FaChevronCircleLeft, FaMapMarkerAlt} from "react-icons/fa";
import moment from "moment/moment";
import SessionDAO from "../dao/SessionDAO";
import _ from "lodash";
import CustomScrollbarTabbing from "./CustomScrollbarTabbing";
import Slider from "react-slick";
import SpeakerCard from "./SpeakerCard";
import {Scrollbars} from "react-custom-scrollbars";
import CategoryIcons from "../misc/CategoryIcons";

const roomSelection = ["Wahyu Sihombing Theatre", "Belajar Room", "Berkarya Room", "Sjuman Djaja Theatre", "Emiria Soenassa Gallery", "S. Sudjojono Gallery"];

export default function AgendaComponentV2(props) {
    const [activePage, setActivePage] = useState(0)
    const [tabs, setTabs] = useState([
        {title: '11 November', subtitle: "Day 1"},
        {title: '12 November', subtitle: "Day 2"},
        {title: '13 November', subtitle: "Day 3"},
    ]);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [scheduleRef, setScheduleRef] = useState(null)

    useEffect(() => {
        let date = moment(new Date()).format("DD");
        // let date = "12"
        if (date === "11") {
            setActivePage(0);
        }
        else if (date === "12") {
            setActivePage(1);
        }
        else if (date === "13") {
            setActivePage(2);
        }
    }, [])

    const renderBadge = (category) => {
        let backgroundColor = 'grey';

        switch (category) {
            case 'LitBeat':
                backgroundColor = 'blue';
                break;

            case 'LitFest':
                backgroundColor = 'red';
                break;

            case 'LitBite':
                backgroundColor = 'green';
                break;

            case 'The Market':
                backgroundColor = 'purple';
                break;

            case 'Icon':
                backgroundColor = 'brown';
                break;
        }

        return (
            <div style={{
                marginTop: windowWidth < 768 ? 6 : undefined,
                marginBottom: windowWidth < 768 ? 6 : undefined,
                marginLeft: windowWidth < 768 ? undefined : 15,
                marginRight: 15,
                backgroundColor,
                fontSize: 14,
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 4,
                paddingBottom: 4,
                borderRadius: 10
            }}><img src={'icon_white_placeholder.png'} style={{
                maxWidth: windowWidth > 768 ? 15 : 10,
                transition: 'max-width .5s',
                objectFit: 'contain',
                marginRight: 8
            }}/>{category}</div>
        )
    }

    const [realFormattedSchedule, setRealFormattedSchedule] = useState([])

    const [page, setPage] = useState(0)

    const [carouselSettings, setCarouselSettings] = useState({
        dots: false,
        speed: 500,
        slidesToShow: 2.3,
        slidesToScroll: 1,
        autoplay: true
    });

    const scrollToRight=()=>{
        let scrollComponent = document.getElementById("schedule-scrollbar");
        if(scheduleRef){
            // scheduleRef.scrollToRight()
            scheduleRef.view.scrollTo({left : 1000000, behavior : "smooth"})
        }
        // scrollComponent.scrollTo({left : 100, behavior : "smooth"});
    }

    const scrollToLeft=()=>{
        let scrollComponent = document.getElementById("schedule-scrollbar");
        if(scheduleRef){
            // scheduleRef.scrollToRight()
            scheduleRef.view.scrollTo({left : 0, behavior : "smooth"})
        }
        // scrollComponent.scrollTo({left : 100, behavior : "smooth"});
    }

    const renderDateNode = () => {

        if (realFormattedSchedule.length === 0) {
            return
        }

        let sessions = realFormattedSchedule[activePage]
        let sessionsInDate = sessions.sessionsInDate


        let roomSelection = ["Wahyu Sihombing Theatre", "Belajar Room", "Berkarya Room", "Sjuman Djaja Theatre", "Emiria Soenassa Gallery", "S. Sudjojono Gallery", "Others"]

        // if (windowWidth < 768) {
        //     roomSelection = [roomSelection[0]]
        // }

        return <Scrollbars
            id={"schedule-scrollbar"}
            ref={ref=>{setScheduleRef(ref)}}
            autoHide
            universal
            hideTracksWhenNotNeeded={true}
            autoHeightMax={10000}
            style={{width: '100%'}} autoHeight renderThumbHorizontal={({style, ...props}) =>
            <div {...props} style={{...style, backgroundColor: Palette.DARK_GRAY, opacity: .2, borderRadius: 500}}/>
        }>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: (windowWidth < 768) ? "630vw" : "210vw",
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                }}
                >
                    <div style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 100
                    }}>
                    </div>
                    {
                        roomSelection.map(obj => {
                            return <div style={{
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                flex: 1,
                                padding: 4,
                                fontSize: "1.2em",
                                fontFamily: "Interstate",
                                fontWeight: "bold"
                            }}>
                                <FaMapMarkerAlt color={Palette.PINK}/>&nbsp;&nbsp;{obj}
                            </div>
                        })
                    }
                </div>
                {
                    sessionsInDate.map((obj, key) => {
                        return <div style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            height: "100%",
                            flexGrow: 1,
                            // background : "green"
                        }}>
                            {renderHourNode(obj, key)}
                        </div>
                    })
                }
            </div>
        </Scrollbars>
    }

    const renderHourNode = (sessions, key) => {

        const colorSelection = [Palette.AGENDA_GREEN, Palette.AGENDA_BLUE, Palette.AGENDA_PURPLE]
        let selectedColor = colorSelection[key % colorSelection.length];

        let sessionsInHour = sessions.sessionsInHour

        // let roomSelection = page === 0 ? ["Main Stage", "Room 1", "Room 2", "Room 3", "Room 4"] : ["Room 3", "Room 4"]
        let roomSelection = ["Wahyu Sihombing Theatre", "Belajar Room", "Berkarya Room", "Sjuman Djaja Theatre", "Emiria Soenassa Gallery", "S. Sudjojono Gallery", "Others"]

        // if (windowWidth < 768) {
        //     roomSelection = [roomSelection[0]]
        // }

        let getSessionInRoom = (room) => {
            for (let session of sessionsInHour) {
                if (session.location === room) {
                    return session
                }
            }
        }

        return <>
            <div style={{
                fontWeight: "bold",
                fontSize: "1.2em",
                color: "black",
                width: 100,
                padding: 4,
            }}>
                <div style={{
                    background: Palette.LIGHT_GRAY,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%"
                }}>
                    {sessions.time}
                </div>
            </div>
            {
                roomSelection.map(obj => {
                    return renderSessionNode(getSessionInRoom(obj))
                })
            }
            {/*{sessionsInHour?.map((obj, key) => {*/}
            {/*    return renderSessionNode(obj, key, sessionsInHour.length)*/}
            {/*})}*/}

        </>
    }

    const renderSessionNode = (obj, key, length) => {

        if (!obj) {
            return <div
                style={{
                    flex: 1,
                    width: "50%",
                    padding: 4,
                }}
            >
                <div style={{
                    background: Palette.LIGHT_DARK_GRAY,
                    paddingTop: 10, paddingBottom: 10,
                    paddingLeft: 20, paddingRight: 20,
                    height: "100%",
                }}>
                </div>
            </div>
        }

        return <div
            style={{
                flex: 1,
                width: "50%",
                padding: 4,
                alignItems: "stretch"
            }}
        >
            <Row style={{
                margin: 0,
                background: Palette.LIGHT_DARK_GRAY,
                paddingTop: 10, paddingBottom: 10,
                paddingLeft: 10, paddingRight: 10,
                height: "100%",
            }}>
                <Col xs={12} md={10}>
                    <div style={{fontSize: "1.4em"}}>
                        <div style={{
                            display: 'flex',
                            flexDirection: windowWidth < 768 ? 'column' : 'row',
                            alignItems: 'flex-start',
                        }}>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: Palette.DARK_GRAY,
                                    flex: 1,
                                    fontFamily: "Interstate",
                                    fontWeight: "bold"
                                }}
                                to={"/event/" + obj.id}>
                                <div style={{flex: 1}}>{obj.name}</div>
                            </Link>
                        </div>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: Palette.DARK_GRAY
                            }}
                            to={"/event/" + obj.id}>
                            <Row>
                                <Col md={12} lg={12} sm={12}>
                                    <div style={{
                                        display: "flex", flexDirection: "row",
                                        fontWeight: 400, alignItems: "center", fontSize: "0.7em"
                                    }}>
                                        {moment(obj.start_time).format("DD MMMM, HH:mm")}-{moment(obj.end_time).format("HH:mm")}
                                    </div>
                                </Col>
                            </Row>
                        </Link>
                    </div>

                    <div
                        style={{
                            width: "100%",
                            marginTop: 15,
                            fontSize: "1.2em"
                        }}
                    >
                        <Row style={{width: "100%"}}>
                            {
                                obj.speakers && obj.speakers.map(obj => {
                                    return <>
                                        <Col
                                            style={{
                                                display: "flex",
                                                flexDirection: windowWidth < 600 ? "column" : "row",
                                                alignItems: "center", paddingBottom: 10,
                                                textAlign: windowWidth < 600 ? 'center' : 'start',
                                                textDecoration: "none"
                                            }}
                                            lg={12} xs={12} md={12}
                                        >
                                            <Link
                                                style={{
                                                    textDecoration: "none",
                                                    color: Palette.DARK_GRAY
                                                }}
                                                to={"/speaker/" + obj.id}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: windowWidth < 600 ? "column" : "row",
                                                        textAlign: windowWidth < 600 ? 'center' : 'start',
                                                        alignItems: windowWidth < 600 ? 'center' : 'start',
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            width: 60,
                                                            height: 60,
                                                            borderRadius: 60,
                                                            objectFit: "cover",
                                                            // border: `solid 2px ${Palette.ORANGE}`,
                                                            marginRight: windowWidth < 600 ? undefined : 10
                                                        }}
                                                        src={obj.photo ? obj.photo : "https://wellsource.sgp1.digitaloceanspaces.com/jaktent/uploads/file/1666955370768-file.png"}/>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 1,
                                                        fontWeight: 400,
                                                        fontSize: "0.8em"
                                                    }}>
                                                        <b>{obj.name}</b>
                                                        <div style={{fontSize: "0.8em"}}>{obj.title}</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                    </>
                                })
                            }
                        </Row>
                    </div>
                </Col>
                {windowWidth >= 768 &&
                    <Col xs={12} md={2} style={{borderLeft: `1px solid ${Palette.DARK_GRAY}55`}}>
                        {CategoryIcons.renderBadge(windowWidth, obj.categories[0]?.name)}
                    </Col>
                }
            </Row>
        </div>
        {/*{*/
        }
        {/*    key < length - 1 &&*/
        }
        {/*    <div style={{height: 1, background: "#ffffff80", marginTop: 20, marginBottom: 20, width: "100%"}}></div>*/
        }
        {/*}*/
        }

    }

    const getEventData = async () => {
        try {
            let result = await SessionDAO.getAll()
            // console.log("Result1", result)

            let result2 = result.map((obj, key) => {
                for (let room of roomSelection) {
                    if (obj.location === room)
                        return obj;
                }
                return {
                    ...obj,
                    location: "Others"
                }
            })
            // console.log("Result2", result2)

            let groupedByRoom = _.groupBy(result2, (obj) => {

            })

            let groupedByDate = _.groupBy(result2, (obj) => {
                return moment(obj.start_time).format("DD MMM")
            })

            let formattedData = []

            for (let date of Object.keys(groupedByDate)) {
                let sessionsInDate = groupedByDate[date]
                let groupedByHour = _.groupBy(sessionsInDate, (obj) => {
                    return moment(obj.start_time).format("HH") + ".00"
                })

                let groupedByHourFormatted = []

                for (let startHour of Object.keys(groupedByHour)) {

                    groupedByHourFormatted.push({
                        time: startHour,
                        sessionsInHour: groupedByHour[startHour]
                    })
                }

                formattedData.push({
                    date: date,
                    sessionsInDate: groupedByHourFormatted
                })

            }

            setRealFormattedSchedule(formattedData)
            console.log("Test", formattedData);

        } catch (e) {
            console.log(e)
        }
    }

    const renderAgendaSection = () => {
        return <Container fluid style={{margin: 0, padding: 0}}>
            <Row>
                <Col sm={2}>

                </Col>
                <Col sm={8}>
                    <div style={{
                        color: "white",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row"
                    }}>
                        <CustomScrollbarTabbing
                            style={{flex: 1}}
                            tabs={tabs}
                            setTabs={setTabs}
                            activePage={activePage}
                            setActivePage={setActivePage}
                            windowWidth={windowWidth}
                        />
                        <div style={{
                            color: Palette.DARK_GRAY,
                            display : "flex",
                            flexDirection : "row",
                            alignItems : "center",
                            fontSize : "2em"
                        }}>
                            <FaChevronCircleLeft
                                onClick={()=>{
                                    scrollToLeft()
                                }}
                                style={{
                                    cursor : "pointer"
                                }}
                            />
                            <FaChevronCircleRight
                                onClick={()=>{
                                    scrollToRight()
                                }}
                                style={{
                                    cursor : "pointer"
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col sm={2}>

                </Col>
            </Row>
            <Row style={{
                display: "flex",
                justifyContent: "center",
                padding: 0
            }}>
                <Col
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: 0
                    }}
                    sm={8}
                >
                    {renderDateNode()}

                </Col>
            </Row>
        </Container>

    }

    useEffect(() => {
        getEventData()
    }, [])

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return renderAgendaSection()

}
