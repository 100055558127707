import AppContainer from "../components/AppContainer";
import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import SpeakerCard from "../components/SpeakerCard";
import SpeakerDAO from "../dao/SpeakerDAO";
import LoadingProgress from "../components/LoadingProgress";
import Palette from "../utils/Palette";

export default function Speakers() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [speakers, setSpeakers] = useState([]);
    const [isDataFetched, setIsDataFetched] = useState(false);

    const [curatorCarouselSettings, setCuratorCarouselSettings] = useState({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true
    });

    const getSpeakers = async () => {
        try {
            let result = await SpeakerDAO.getAll();
            await setSpeakers(result);
            setIsDataFetched(true);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, []);

    useEffect(() => {
        getSpeakers();
    }, []);

    useEffect(() => {
        const carouselSettingsTemp = {...curatorCarouselSettings};

        if (windowWidth < 768) {
            carouselSettingsTemp.slidesToShow = 1
        } else if (windowWidth < 1200) {
            carouselSettingsTemp.slidesToShow = 2
        } else {
            carouselSettingsTemp.slidesToShow = 4
        }

        setCuratorCarouselSettings(carouselSettingsTemp)
    }, [windowWidth])

    let speakerColors = [Palette.PINK, Palette.GREEN, Palette.ORANGE, Palette.RED]

    return (
        <AppContainer title={"Speakers"}>
            <Container style={{
                maxWidth : 1100
            }}>
                <Row style={{
                    width: "100%",
                    padding: 0,
                    margin: 0,
                    marginBottom : "4em"
                }}
                >
                    <Col
                        style={{
                            fontFamily : "Anton",
                            textAlign : "center",
                            fontSize: windowWidth > 768 ? "6em" : '4em',
                            transition: 'font-size .5s',
                            marginTop : "0.5em",
                            marginBottom : "0.5em"
                        }}
                        md={12}>
                        SPEAKERS
                    </Col>
                    {!isDataFetched ?
                        <Col
                            style={{
                                fontFamily : "Anton",
                                textAlign : "center",
                                fontSize : "6em",
                                marginTop : "0.5em",
                                marginBottom : "0.5em"
                            }}
                            md={12}>
                            <LoadingProgress />
                        </Col> :

                        <>
                            {speakers.map((obj,key)=>{
                                return <Col xl={3} md={4} sm={6}>
                                    <SpeakerCard
                                        speaker={obj}
                                        isMobile={windowWidth < 1000}
                                        height="350px"
                                        activeColor={speakerColors[key%speakerColors.length]+"90"}
                                    />
                                </Col>
                            })}
                        </>
                    }
                </Row>
            </Container>
        </AppContainer>
    )
}
