import partner1 from '../assets/partners/LOGO IDX CHANNEL.png';
import partner2 from '../assets/partners/LOGO OKEZONE.png';
import partner3 from '../assets/partners/Logo tempo 1.png';
import partner4 from '../assets/partners/logo Republika-01.png';
import partner5 from '../assets/partners/Logo Greatmind-01 (1).png';
import partner6 from '../assets/partners/Logo Medcom (Baru) 01.png';
import partner7 from '../assets/partners/Logo MI-1.png';
import partner8 from '../assets/partners/Metro tv 2.png';
import partner9 from '../assets/partners/Logo Jkt Creative Media.png';
import partner10 from '../assets/partners/Logo suara 2.png';
import partner11 from '../assets/partners/V Radio.png';
import partner12 from '../assets/partners/mnctrijayafm.png';
import partner13 from '../assets/partners/TFR.png';
import partner14 from '../assets/partners/Logo Brilio 2020.png';
import partner15 from '../assets/partners/Logo Men Obsession_.png';
import partner16 from '../assets/partners/Logo Women Obsession_.png';

const partners = [
    partner1,
    partner2,
    partner3,
    partner4,
    partner5,
    partner6,
    partner7,
    partner8,
    partner9,
    partner10,
    partner11,
    partner12,
    partner13,
    partner14,
    partner15,
    partner16,
]

export default partners;
