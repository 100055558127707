import {Card, Col, Container, Dropdown, Row} from "react-bootstrap";
import {AiOutlineMenu} from "react-icons/ai";
import Palette from "../utils/Palette";
import {Link} from "react-router-dom";
import DropdownItem from "react-bootstrap/DropdownItem";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import Utils from "../utils/Utils";

export default function Header(props) {

    const {
        removeTopPadding,
        windowWidth,
        defaultHeaderMenu,
        headerMenu,
        setHeaderMenu,
        setSidebarOpen,
        setIsQRModalOpen
    } = props;

    return (
        <Container fluid>
            <Card style={{
                margin: windowWidth > 770 ? '30px 120px' : '30px 0',
                padding: (windowWidth > 770) ? "0 6.5%" : "0 10%",
                width: (removeTopPadding && windowWidth > 770) ? "calc(100vw - 240px)" : null,
                backgroundColor: 'transparent',
                backgroundImage: 'url(/new/Menu-Banner.png)',
                backgroundSize: "100% 100%",
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundOrigin: 'padding-box',
                position: (removeTopPadding && windowWidth > 770) ? "absolute" : null,
                border: 0,
                borderRadius: 0,
                zIndex: 2
            }}>
                <Card.Body>
                    <Row>
                        <Col xs={2} style={{display: 'flex', alignItems: 'center'}}>
                            <Link to={'/home'}>
                                <img src={'/new/logo_white2.png'} style={{maxWidth: 130, objectFit: "contain", height: 75}} alt='logo'/>
                            </Link>
                        </Col>

                        <Col xs={8} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            {
                                windowWidth > 1388 ?
                                    headerMenu.map((menu, idx) => (
                                        <Link to={menu.route} style={{textDecorationLine: 'none'}}>
                                            <div
                                                onMouseOver={() => {
                                                    const headerMenuTemp = [...defaultHeaderMenu];

                                                    headerMenuTemp[idx].hovered = true;

                                                    setHeaderMenu(headerMenuTemp)
                                                }}
                                                onMouseLeave={() => {
                                                    const headerMenuTemp = [...defaultHeaderMenu];

                                                    setHeaderMenu(headerMenuTemp)
                                                }}
                                                style={{
                                                    fontSize: 16,
                                                    fontWeight: 700,
                                                    padding: '0px 12px 0px 12px',
                                                    letterSpacing: .2,
                                                    userSelect: 'none',
                                                    cursor: 'pointer',
                                                    color: menu.selected || menu.hovered ? '#ffcf0c' : 'white'
                                                }}>
                                                {menu.title.toUpperCase()}
                                            </div>
                                        </Link>
                                    )) : null
                            }
                        </Col>

                        <Col xs={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'right'}}>
                            {
                                windowWidth <= 1388 ?
                                    <div
                                        onClick={() => setSidebarOpen(true)}
                                        style={{
                                            border: '2px solid white',
                                            padding: '3px 11px 5px 11px',
                                            marginRight: windowWidth > 770 ? 30 : 0,
                                            cursor: 'pointer'
                                        }}>
                                        <AiOutlineMenu size={20} color={"white"}/>
                                    </div> : null
                            }

                            {/* {windowWidth > 1388 &&
                                <>
                                    {localStorage.getItem("name") === null ?
                                        <Link to={'/login'} style={{textDecorationLine: 'none'}}>
                                            <div style={{
                                                fontWeight: 700,
                                                backgroundColor: Palette.YELLOW,
                                                borderRadius: 100,
                                                cursor: 'pointer',
                                                userSelect: 'none',
                                                color: 'black',
                                                fontSize: 16,
                                                padding: '15px 45px 15px 45px'
                                            }}>
                                                LOGIN
                                            </div>
                                        </Link> :
                                        <Dropdown align="end">
                                            <DropdownToggle variant="link" style={{
                                                fontSize: 16,
                                                fontWeight: 700,
                                                padding: '0px 0px 0px 17px',
                                                letterSpacing: .2,
                                                userSelect: 'none',
                                                cursor: 'pointer',
                                                textDecoration: 'none',
                                                color: 'white'
                                            }}>Hi, {localStorage.getItem("name").split(" ")[0]}!</DropdownToggle>
                                            <DropdownMenu>
                                                <Link to={'/my-events'} className={'dropdown-item'}>
                                                    My Events
                                                </Link>
                                                <Link to={'/edit-profile'} className={'dropdown-item'}>
                                                    Edit Profile
                                                </Link>
                                                <DropdownItem onClick={() => setIsQRModalOpen(true)}>
                                                    Show QR Code
                                                </DropdownItem>
                                                <Dropdown.Divider />
                                                <DropdownItem onClick={() => Utils.logout()}>Logout</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    }
                                </>
                            } */}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    )
}
