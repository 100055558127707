import {Modal} from "react-bootstrap";
import QRCode from "react-qr-code";
import React, {useEffect, useState} from "react";
import moment from "moment";
import Palette from "../utils/Palette";

export default function QRModal({isQRModalOpen, setIsQRModalOpen}) {

    const [currentDay, setCurrentDay] = useState(null);
    const [date, setDate] = useState("");

    const checkDay = () => {
        let date = moment(new Date()).format("YYYY-MM-DD");
        // date = "2022-11-13";
        if (date === "2022-11-11")setCurrentDay(1);
        else if (date === "2022-11-12") setCurrentDay(2);
        else if (date === "2022-11-13") setCurrentDay(3);
        else {
            setCurrentDay(1);
            setDate("November 11, 2022");
            return;
        }
        setDate(moment(new Date()).format("MMMM DD, YYYY"));
    }

    useEffect(() => {
        checkDay();
    }, [])

    return (
        <Modal show={isQRModalOpen} fullscreen centered onClick={() => setIsQRModalOpen(false)} size={"lg"}>
            <Modal.Body style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                {currentDay !== null ?
                    <>
                        <p style={{textAlign: "center", fontSize: "1.2em"}}>Day {currentDay} - {date}</p>
                        <p style={{textAlign: "center", fontSize: "1.2em"}}>{localStorage.getItem("name")}</p>
                        <br />
                        <QRCode size={512} viewBox={`0 0 512 512`} style={{maxWidth: '75%', height: 'auto'}} value={`${process.env.REACT_APP_ADMIN_URL}#/attendance?attending=1&userId=${localStorage.getItem("userId")}&day=${currentDay}`} />
                        <br />
                        <p style={{textAlign: "center", fontSize: "1.2em"}}>Show this QR code to the committee before entering the venue.</p>
                        <p style={{textAlign: "center", fontSize: "1em", color: Palette.RED, fontWeight: "bold"}}>Tap/click on the screen to exit.</p>
                    </> :
                    <>
                        <p style={{textAlign: "center", fontSize: "1.2em"}}>
                            QR codes will be available on November 11-13, 2022.
                            <br />
                            Make sure to prepare your mobile phone each day before entering the venue.
                        </p>
                        <p style={{textAlign: "center", fontSize: "1em", color: Palette.RED, fontWeight: "bold"}}>Tap/click on the screen to exit.</p>
                    </>
                }
            </Modal.Body>
        </Modal>
    )
}
