import {Container} from "react-bootstrap";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import QRModal from "./QRModal";

export default function AppContainer (props) {
    const {children, removeTopPadding, title} = props;
    let defaultHeaderMenu = [
        {title: 'Home', route: '/home'},
        {title: 'About', route: '/about'},
        {title: 'Agenda', route: '/agenda'},
        {title: 'Speakers', route: '/speakers'},
        {title: 'Curators', route: '/curators'},
        {title: 'Programs', route: '/programs'},
    ]

    defaultHeaderMenu.forEach((menu, idx) => {
        if(menu.route === window.location.pathname) {
            defaultHeaderMenu[idx].selected = true;
        }
    })

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [headerMenu, setHeaderMenu] = useState(defaultHeaderMenu);
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [isQRModalOpen, setIsQRModalOpen] = useState(false);

    useEffect(() => {
        if (windowWidth > 1388) setSidebarOpen(false)
    }, [windowWidth])

    useEffect(() => {

        window.scroll(0,0)

        const handleResize = () => setWindowWidth(window.innerWidth)

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        document.title = title ? `${title} - Jaktent` : "Jaktent";
    }, [title])

    return (
        <Container fluid style={{fontFamily: 'Interstate', padding : 0, backgroundColor : "#ffffff", overflow: 'hidden',}}>
            <Header
                removeTopPadding={removeTopPadding}
                isSidebarOpen={isSidebarOpen} setSidebarOpen={setSidebarOpen} defaultHeaderMenu={defaultHeaderMenu} headerMenu={headerMenu} setHeaderMenu={setHeaderMenu} windowWidth={windowWidth} setIsQRModalOpen={setIsQRModalOpen}
            />

            <Sidebar isSidebarOpen={isSidebarOpen} setSidebarOpen={setSidebarOpen} defaultHeaderMenu={defaultHeaderMenu} headerMenu={headerMenu} setHeaderMenu={setHeaderMenu} windowWidth={windowWidth} setIsQRModalOpen={setIsQRModalOpen}/>

            {children}

            <Footer windowWidth={windowWidth} menu={headerMenu}/>
            <QRModal isQRModalOpen={isQRModalOpen} setIsQRModalOpen={setIsQRModalOpen} />
        </Container>
    )
}
