import React, {useEffect, useState} from "react";
import Palette from "../utils/Palette";
import {Link} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import {FaCalendar, FaMapMarkerAlt} from "react-icons/fa";
import moment from "moment/moment";
import SessionDAO from "../dao/SessionDAO";
import _ from "lodash";
import CustomScrollbarTabbing from "./CustomScrollbarTabbing";
import categoryIcons from "../misc/CategoryIcons";
import CategoryIcons from "../misc/CategoryIcons";

export default function AgendaComponent(props) {
    const [activePage, setActivePage] = useState(0)
    const [tabs, setTabs] = useState([
        {title: '11 Nov', subtitle: "Day 1"},
        {title: '12 Nov', subtitle: "Day 2"},
        {title: '13 Nov', subtitle: "Day 3"},
    ]);

    useEffect(() => {
        let date = moment(new Date()).format("DD");
        // let date = "12"
        if (date === "11") {
            setActivePage(0);
        }
        else if (date === "12") {
            setActivePage(1);
        }
        else if (date === "13") {
            setActivePage(2);
        }
    }, [])

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [realFormattedSchedule, setRealFormattedSchedule] = useState([])

    const renderDateNode = () => {

        if (realFormattedSchedule.length === 0) {
            return
        }

        let sessions = realFormattedSchedule[activePage]
        let sessionsInDate = sessions.sessionsInDate

        return <>
            {
                sessionsInDate.map((obj, key) => {
                    return renderHourNode(obj, key)
                })
            }
        </>
    }

    const renderHourNode = (sessions, key) => {

        const colorSelection = [Palette.AGENDA_GREEN, Palette.AGENDA_BLUE, Palette.AGENDA_PURPLE]
        let selectedColor = colorSelection[key % colorSelection.length];

        let sessionsInHour = sessions.sessionsInHour

        return <>
            <div style={{
                background: Palette.LIGHT_GRAY,
                width: "100%",
                color: "white",
                display: "flex",
                flexDirection: "row",
                padding: 10,
                marginBottom: 10
            }}>
                <div style={{
                    width: 150,
                    background: Palette.DARK_GRAY,
                    fontWeight: "bold",
                    fontSize: "1.2em",
                    padding: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    {sessions.time}
                </div>
                <div style={{
                    paddingLeft: windowWidth < 600 ? 15 : 50,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    fontWeight: "bold",
                    fontSize: "1.2em",
                    width: "100%"
                }}>
                    {sessionsInHour?.map((obj, key) => {
                        return renderSessionNode(obj, key, sessionsInHour.length)
                    })}
                </div>
            </div>
        </>
    }

    const renderSessionNode = (obj, key, length) => {
        return <>
            <Row style={{width: '100%', paddingTop: 10, paddingBottom: 10}}>
                <Col xs={12} md={11}>

                    <div>
                        <div style={{
                            display: 'flex',
                            flexDirection: windowWidth < 768 ? 'column' : 'row',
                            alignItems: 'flex-start',
                            marginBottom: 6
                        }}>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: Palette.DARK_GRAY,
                                    flex: 1,
                                }}
                                to={"/event/" + obj.id}>
                                <div style={{flex: 1}}>{obj.name}</div>
                            </Link>
                        </div>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: Palette.DARK_GRAY
                            }}
                            to={"/event/" + obj.id}>
                            <Row>
                                <Col md={6} lg={4} sm={12}>
                                    <div style={{
                                        display: "flex", flexDirection: "row",
                                        fontWeight: 400, alignItems: "center", fontSize: "0.8em"
                                    }}>
                                        <div style={{marginRight: 10}}>
                                            <FaCalendar/>
                                        </div>
                                        {moment(obj.start_time).format("DD MMMM, HH:mm")}-{moment(obj.end_time).format("HH:mm")}
                                    </div>
                                </Col>
                                <Col md={4} sm={12}>
                                    <div style={{
                                        display: "flex", flexDirection: "row",
                                        fontWeight: 400, alignItems: "center", fontSize: "0.8em"
                                    }}>

                                        <div style={{marginRight: 10}}>
                                            <FaMapMarkerAlt/>
                                        </div>
                                        <div>{obj.location}</div>
                                    </div>
                                </Col>
                            </Row>
                        </Link>
                    </div>

                    <div
                        style={{
                            width: "100%",
                            marginTop: 15
                        }}
                    >
                        <Row style={{width: "100%"}}>
                            {
                                obj.speakers && obj.speakers.map(obj => {
                                    return <>
                                        <Col
                                            style={{
                                                display: "flex",
                                                flexDirection: windowWidth < 600 ? "column" : "row",
                                                alignItems: "center", paddingBottom: 10,
                                                textAlign: windowWidth < 600 ? 'center' : 'start',
                                                textDecoration: "none"
                                            }}
                                            lg={4} xs={12} md={4}
                                        >
                                            <Link
                                                style={{
                                                    textDecoration: "none",
                                                    color: Palette.DARK_GRAY
                                                }}
                                                to={"/speaker/" + obj.id}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: windowWidth < 600 ? "column" : "row",
                                                        textAlign: windowWidth < 600 ? 'center' : 'start',
                                                        alignItems: windowWidth < 600 ? 'center' : 'start',
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            width: 60,
                                                            height: 60,
                                                            borderRadius: 60,
                                                            objectFit: "cover",
                                                            border: `solid 2px ${Palette.ORANGE}`,
                                                            marginRight: windowWidth < 600 ? undefined : 10
                                                        }}
                                                        src={obj.photo ? obj.photo : "https://wellsource.sgp1.digitaloceanspaces.com/jaktent/uploads/file/1666955370768-file.png"}/>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 1,
                                                        fontWeight: 400,
                                                        fontSize: "0.8em"
                                                    }}>
                                                        <b>{obj.name}</b>
                                                        <div style={{fontSize: "0.8em"}}>{obj.title}</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                    </>
                                })
                            }
                        </Row>
                    </div>
                </Col>
                <Col xs={12} md={1}>
                    {CategoryIcons.renderBadge(windowWidth, obj.categories[0]?.name)}
                </Col>
            </Row>
            {
                key < length - 1 &&
                <div style={{height: 1, background: "#ffffff80", marginTop: 20, marginBottom: 20, width: "100%"}}></div>
            }
        </>
    }

    const getEventData = async () => {
        try {
            let result2 = await SessionDAO.getAll()

            let groupedByDate = _.groupBy(result2, (obj) => {
                return moment(obj.start_time).format("DD MMM")
            })

            let formattedData = []

            for (let date of Object.keys(groupedByDate)) {
                let sessionsInDate = groupedByDate[date]
                let groupedByHour = _.groupBy(sessionsInDate, (obj) => {
                    return moment(obj.start_time).format("HH") + ".00"
                })
                let groupedByHourFormatted = []
                for (let startHour of Object.keys(groupedByHour)) {
                    groupedByHourFormatted.push({
                        time: startHour,
                        sessionsInHour: groupedByHour[startHour]
                    })
                }
                formattedData.push({
                    date: date,
                    sessionsInDate: groupedByHourFormatted
                })
            }

            setRealFormattedSchedule(formattedData)

        } catch (e) {
            console.log(e)
        }
    }

    const renderAgendaSection = () => {
        return <Container>
            <Row>
                <Col
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                    md={12}
                >
                    <div style={{
                        color: "white",
                        width: "100%"
                    }}>
                        <CustomScrollbarTabbing
                            tabs={tabs}
                            setTabs={setTabs}
                            activePage={activePage}
                            setActivePage={setActivePage}
                            windowWidth={windowWidth}
                        />
                    </div>

                    {renderDateNode()}

                </Col>
            </Row>
        </Container>

    }

    useEffect(() => {
        getEventData()
    }, [])

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return renderAgendaSection()

}
