import theicon from '../assets/categories/PROGRAM_ICON.png';
import litbeat from '../assets/categories/PROGRAM_LITBEAT.png';
import litbite from '../assets/categories/PROGRAM_LITBITE.png';
import litfest from '../assets/categories/PROGRAM_LITFEST.png';
import litfilm from '../assets/categories/PROGRAM_LITFILM.png';
import littrade from '../assets/categories/PROGRAM_LITTRADE.png';
import themarket from '../assets/categories/PROGRAM_THE MARKET.png';
import React from "react";

const categoryIcons = {
    theicon: theicon,
    litbeat: litbeat,
    litbite: litbite,
    litfest: litfest,
    litfilm: litfilm,
    littrade: littrade,
    themarket: themarket
}

export default class CategoryIcons {
    static getIcons = () => {
        return categoryIcons;
    }

    static renderBadge = (windowWidth, category, maxWidth) => {
        let image;

        switch (category) {
            case 'LitBeat':
                image = categoryIcons.litbeat;
                break;
            case 'LitFest':
                image = categoryIcons.litfest;
                break;
            case 'LitBite':
                image = categoryIcons.litbite;
                break;
            case 'The Market':
                image = categoryIcons.themarket;
                break;
            case 'Icon':
                image = categoryIcons.theicon;
                break;
            case 'LitFilm':
                image = categoryIcons.litfilm;
                break;
            case 'LitTrade':
                image = categoryIcons.littrade;
                break;
            default:
                break;
        }

        return (
            <div style={{
                height: '100%',
                marginTop: windowWidth < 768 ? 6 : undefined,
                marginBottom: windowWidth < 768 ? 6 : undefined,
                marginLeft: windowWidth < 768 ? undefined : 15,
                marginRight: 0,
                fontSize: 14,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 4,
                paddingBottom: 4,
                borderRadius: 10
            }}>
                <img src={image} style={{
                    maxWidth: (maxWidth) ? maxWidth : (windowWidth > 768 ? 75 : 50),
                    maxHeight: "100%",
                    transition: 'max-width .5s',
                    objectFit: 'contain',
                    marginRight: 15
                }}/>
            </div>
        )
    }
}