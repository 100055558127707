import AppContainer from "../components/AppContainer";
import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";

import AgendaComponent from "../components/AgendaComponent";

export default function Agenda() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <AppContainer title={"Agenda"}>
            <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <Row style={{
                    width: "100%",
                    padding: 0,
                    margin: 0,
                    maxWidth: 1100,
                    paddingBottom: 100
                }}>
                    <Col md={12}>
                        <div style={{
                            fontSize: windowWidth > 768 ? "6em" : '4em',
                            transition: 'font-size .5s',
                            fontFamily: "Anton",
                            color: "black",
                            width: "100%",
                            textAlign: "center",
                            marginTop: 100,
                            marginBottom: 30
                        }}>
                            AGENDA
                        </div>
                    </Col>

                    <Col md={12}>
                        <Col
                            style={{
                                paddingTop: 75,
                                paddingBottom: 75,
                                background: "white",
                                paddingLeft: windowWidth < 600 ? undefined : 50,
                                paddingRight: windowWidth < 600 ? undefined : 50
                            }}
                            md={12}>
                            <AgendaComponent/>
                        </Col>
                    </Col>

                </Row>
            </div>
        </AppContainer>
    )
}
