export default class Palette {
    static PRIMARY = "#F75B57";
    static SECONDARY = "#F7C831";
    static AGENDA_BLACK = "#101114";
    static AGENDA_GREEN = "#0bc07f";
    static AGENDA_BLUE = "#4999e8";
    static AGENDA_PURPLE = "#9d76ff";

    static PINK = "#ec3495";
    static GREEN = "#50b14d";
    static LIGHT_GRAY = "#d2d3d5";
    static LIGHT_DARK_GRAY = "#bdbec0";
    static DARK_GRAY = "#414143";
    static GRAY = "#d2d3d5";
    static ORANGE = "#da671d";
    static RED = "#e11f26";
    static YELLOW = "#ffcf0c";

}
