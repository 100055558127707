import AppContainer from "../components/AppContainer";
import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {useNavigate, useSearchParams} from "react-router-dom";
import Palette from "../utils/Palette";
import CustomScrollbarTabbing from "../components/CustomScrollbarTabbing";
import SessionDAO from "../dao/SessionDAO";
import _ from "lodash";
import moment from "moment";
import SessionsList from "../components/SessionsList";
import swal from "../components/CustomSweetAlert";

export default function MyEvents() {

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [sessions, setSessions] = useState({});
    const [activePage, setActivePage] = useState(0)
    const [dates, setDates] = useState([
        {title: '11 Nov', subtitle: "Day 1"},
        {title: '12 Nov', subtitle: "Day 2"},
        {title: '13 Nov', subtitle: "Day 3"},
    ]);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const getSessions = async () => {
        try {
            let result = await SessionDAO.getAllBySelf();
            let groupedByDate = _.groupBy(result, (obj) => {
                return moment(obj.start_time).format("DD MMM")
            })
            await setSessions(groupedByDate);
            console.log(groupedByDate);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        let date = moment(new Date()).format("DD");
        // let date = "12"
        if (date === "11") {
            setActivePage(0);
        }
        else if (date === "12") {
            setActivePage(1);
        }
        else if (date === "13") {
            setActivePage(2);
        }
    }, [])

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, []);

    useEffect(() => {
        if (localStorage.getItem('token') === null)
            return navigate('/login');
        getSessions();
    }, []);

    useEffect(() => {
        const status = searchParams.get('transaction');
        if (status === "settlement" || status === "capture") {
            swal.fire({
                title: "Payment successful",
                text: "Your payment has been successful.",
                icon: "success",
                confirmButtonText: "Ok"
            })
        }
        else if (status === "deny") {
            swal.fire({
                title: "Payment failed",
                text: "Your recent payment has failed. Try again.",
                icon: "error",
                confirmButtonText: "Ok"
            })
        }
        else if (status === "pending") {
            swal.fire({
                title: "Payment in progress.",
                text: "Complete the payment process as soon as possible.",
                icon: "warning",
                confirmButtonText: "Ok"
            })
        }
    }, [searchParams.get('transaction')])
    return (
        <AppContainer title={"My Events"}>
            <div style={{fontFamily: 'anton', fontSize: windowWidth > 768 ? 80: 40,  transition: 'font-size .5s', textAlign: 'center', marginTop: 50, marginBottom: 20}}>
                MY EVENTS
            </div>

            <Container fluid style={{backgroundColor: 'black', color: 'white', padding: 30, marginBottom: 100}}>
                <Container>
                    <CustomScrollbarTabbing activePage={activePage} setActivePage={setActivePage} tabs={dates} windowWidth={windowWidth} color={Palette.LIGHT_GRAY} />
                    <div style={{
                        height: 'auto',
                        transition: 'opacity 3s ease',
                        padding: 15
                    }}>
                        <SessionsList
                            sessions={sessions[dates[activePage].title]}
                            windowWidth={windowWidth}
                            errorMessage="No registered sessions in this date." />
                    </div>
                </Container>
            </Container>

        </AppContainer>
    )
}
