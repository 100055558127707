import {Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import moment from "moment"
import LoadingProgress from "./LoadingProgress";

export default function ScheduleCard({session, windowWidth, md}) {

    const {id, name, date, start_time, end_time, speakers, image_url} = session;
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        if (image_url !== null || image_url !== '')
            setImageLoaded(true)
    }, [image_url])

    return <Col md={md ? md : 6} style={{marginBottom: 20, height: "100%"}}>
        <Link to={'/event/'+id}
              style={{
                  display: 'flex',
                  color: 'white',
                  textDecorationLine: 'none',
                  flexDirection: windowWidth < 410 ? 'column' : 'row'
              }}>
            <Row style={{width: "100%", display: "flex"}}>
                <Col
                    style={{padding: 0}}
                    md={7}>
                    <div style={{
                        width: '100%',
                        aspectRatio: 0.8,
                        display: 'flex',
                        position: 'relative',
                        overflow: 'hidden',
                        borderRadius: 5,
                        borderTopRightRadius: windowWidth < 410 ? 5 : 0,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: windowWidth < 410 ? 0 : 5,
                    }}
                    >
                        {/*<img src={require('../assets/temp_banner/jaktent1.jpg')}*/}
                        {/*     style={{width: '100%', objectFit: 'cover', filter: 'blur(5px)', webkitFilter: 'blur(5px)', transform: 'scale(2)'}}/>*/}
                        <img src={image_url === null || image_url === "" ? require('../assets/temp_banner/TEMPORARY-POSTER.jpg') : image_url}
                             style={{
                                 width: '100%',
                                 objectFit: 'contain',
                                 transition: 'max-width .5s',
                                 position: 'absolute',
                                 top: 0,
                                 bottom: 0,
                                 margin: 'auto 0'
                             }} onLoad={() => setImageLoaded(true)}/>
                        {!imageLoaded &&
                            <div style={{
                                width: '100%',
                                objectFit: 'contain',
                                transition: 'max-width .5s',
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                margin: 'auto 0',
                                alignItems: "center"
                            }}>
                                <div style={{
                                    position: "relative",
                                    top: '50%'
                                }}>
                                    <LoadingProgress />
                                </div>
                            </div>
                        }
                    </div>
                </Col>
                <Col
                    style={{
                        display: "flex",
                        flexDirection: 'column',
                        justifyContent: 'center',
                        border: '2px solid white',
                        borderTopWidth: windowWidth < 700 ? 0 : 2,
                        borderLeftWidth: windowWidth < 700 ? 2 : 0,
                        padding: 15,
                        borderRadius: 5,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: windowWidth < 410 ? 5 : 0,
                        borderTopRightRadius: windowWidth < 410 ? 0 : 5
                    }}
                    sm={12} md={5}>
                    <div
                        style={{fontWeight: '600', fontSize: windowWidth > 768 ? 20 : 16, transition: 'font-size .5s'}}>
                        {name}
                    </div>

                    <div style={{fontSize: windowWidth > 768 ? 16 : 12, transition: 'font-size .5s'}}>
                        {(speakers && speakers.length > 0) &&
                            speakers.map((obj, key) => {
                                return <>{obj.name}{key < speakers.length - 1 ? " | " : ""}</>
                            })
                        }
                    </div>

                    <div style={{fontSize: windowWidth > 768 ? 16 : 12, transition: 'font-size .5s'}}>
                        {moment(start_time).format("DD MMM YYYY")}
                    </div>

                    <div style={{fontSize: windowWidth > 768 ? 16 : 12, transition: 'font-size .5s'}}>
                        {moment(start_time).format("HH:mm")} - {moment(end_time).format("HH:mm")} WIB
                    </div>
                </Col>
            </Row>
        </Link>
    </Col>
}
