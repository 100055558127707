import AppContainer from "../components/AppContainer";
import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {AiOutlineLeft, AiOutlineLock, AiOutlineMail, AiOutlineRight} from "react-icons/ai";
import Palette from "../utils/Palette";
import {Link, useNavigate} from "react-router-dom";
import swal from "../components/CustomSweetAlert";
import UserDAO from "../dao/UserDAO";
import Utils from "../utils/Utils";

export default function Login() {

    const navigate = useNavigate();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorFields, setErrorFields] = useState({
        emailError: '',
        passwordError: '',
        miscError: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const login = async () => {
        if (isSubmitting) return;
        setIsSubmitting(true);

        let errors = validate();
        if (Object.keys(errors).length !== 0) {
            setErrorFields(errors);
            await setIsSubmitting(false);
            return;
        }
        setErrorFields({});

        let body = {
            email: email,
            password: password,
        }
        try {
            let result = await UserDAO.login(body);
            localStorage.setItem("token", result.token);
            localStorage.setItem("name", result.full_name);
            localStorage.setItem("userId", result.id);
            await swal.fire({
                title: 'Success',
                text: 'Successfully logged in!',
                icon: 'success',
                confirmButtonText: 'Okay'
            })
            setIsSubmitting(false);
            navigate('/');
        } catch (e) {
            if (e.code === "EMAIL_NOT_FOUND")
                errors.passwordError = "Invalid e-mail address and/or password."
            else if (e.code === "PASSWORD_WRONG")
                errors.passwordError = "Invalid e-mail address and/or password."
            else {
                errors.miscError = "An error has occurred. Please try again later.";
                console.log(e)
            }
            setErrorFields(errors);
            await setIsSubmitting(false);
            return;
        }
    }

    const validate = () => {
        let temp = {};
        if (email === "")
            temp.emailError = 'Please fill in your e-mail address.'
        else if (!Utils.validateEmail(email))
            temp.emailError = 'Please enter a valid e-mail address.'
        if (password === "")
            temp.passwordError = 'Please fill in your password.'
        return temp;
    }

    const onEnter = async (e) => {
        if (e.key === "Enter") await login();
    }

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <AppContainer title={"Login"}>
            <div style={{
                fontFamily: 'anton',
                fontSize: windowWidth > 768 ? 80 : 40,
                transition: 'font-size .5s',
                textAlign: 'center',
                marginTop: 50,
                marginBottom: 20
            }}>
                LOGIN
            </div>
            <Row>
                <Col style={{marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                    <div style={{position: 'relative'}}>
                        <AiOutlineMail size={20} style={{
                            position: 'absolute',
                            left: 25,
                            top: 0,
                            bottom: 0,
                            margin: 'auto 0'
                        }}/>

                        <input type="email" name="email" placeholder="E-mail" required="" style={{
                            fontSize: 20,
                            border: '3px solid black',
                            padding: '15px 10px 15px 70px',
                            boxShadow: '10px 10px 0px 0px #282833',
                            width: windowWidth > 450 ? 370 : 'calc(100vw - 60px)',
                        }} value={email} onKeyDown={onEnter} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col style={{display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                    <p style={{color: "rgb(247, 91, 87)"}}>{errorFields.emailError}</p>
                </Col>
            </Row>
            <Row>
                <Col style={{marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                    <div style={{position: 'relative'}}>
                        <AiOutlineLock size={20} style={{
                            position: 'absolute',
                            left: 25,
                            top: 0,
                            bottom: 0,
                            margin: 'auto 0'
                        }}/>

                        <input type="password" name="email" placeholder="Password" required="" style={{
                            fontSize: 20,
                            border: '3px solid black',
                            padding: '15px 10px 15px 70px',
                            boxShadow: '10px 10px 0px 0px #282833',
                            width: windowWidth > 450 ? 370 : 'calc(100vw - 60px)',
                        }} value={password} onKeyDown={onEnter} onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col style={{display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                    <p style={{color: "rgb(247, 91, 87)"}}>{errorFields.passwordError}</p>
                </Col>
            </Row>
            <Row>
                <Col style={{marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                    <div style={{position: 'relative'}}>
                        <div style={{
                            width: windowWidth > 450 ? 370 : 'calc(100vw - 60px)',
                        }}>
                            <div style={{
                                fontWeight: 700,
                                backgroundColor: Palette.PRIMARY,
                                cursor: 'pointer',
                                userSelect: 'none',
                                color: 'white',
                                fontSize: 16,
                                padding: '15px 45px 15px 45px',
                                textAlign: 'center',
                                marginBottom: 10
                            }} onClick={login}>
                                LOGIN
                            </div>
                            <div style={{
                                cursor: 'pointer',
                                userSelect: 'none',
                                marginBottom: 10,
                            }}>
                                <Link to={'/forgot-password'}>Forgot your password?</Link>
                            </div>
                            <div style={{
                                cursor: 'pointer',
                                userSelect: 'none',
                            }}>
                                Don't have an account? <Link to={'/register'}>Sign up.</Link>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col style={{marginBottom: 50, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                    <p style={{color: "rgb(247, 91, 87)"}}>{errorFields.miscError}</p>
                </Col>
            </Row>

        </AppContainer>
    )
}
