import AppContainer from "../components/AppContainer";
import React, {useEffect, useState} from "react";
import {Col, Container, Modal, Row, Spinner} from "react-bootstrap";
import {FaCalendar, FaClock, FaMapPin} from "react-icons/fa";
import Palette from "../utils/Palette";
import {FiArrowRight, FiCheck} from "react-icons/fi";
import {Link, useNavigate, useParams} from "react-router-dom";
import Slider from "react-slick";
import SessionDAO from "../dao/SessionDAO";

import moment from "moment"
import ParticipantDAO from "../dao/ParticipantDAO";
import Utils from "../utils/Utils";
import LoadingProgress from "../components/LoadingProgress";
import swal from "../components/CustomSweetAlert";
import QRCode from "react-qr-code";
import CategoryIcons from "../misc/CategoryIcons";

export default function EventDetail() {

    const {id:eventId} = useParams();
    const navigate = useNavigate();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isBtnHovered, setBtnHovered] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isQRModalOpen, setIsQRModalOpen] = useState(false);
    const [isYouTubeModalOpen, setIsYouTubeModalOpen] = useState(false);
    const [embedLink, setEmbedLink] = useState("");

    const [sessionData, setSessionData] = useState(null)
    const [carouselSettings, setCarouselSettings] = useState({
        appendDots: dots => <ul style={{bottom: -40}}>{dots}</ul>,
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    });

    const getData=async()=>{
        try{
            let sessionDetail = await SessionDAO.getById(eventId);
            if (sessionDetail.participantStatus === undefined)
                sessionDetail = {
                    ...sessionDetail,
                    participantStatus: null
                }

            await setSessionData(sessionDetail);
            await setIsDataLoading(false);
            console.log(sessionDetail);
        } catch (e){
            console.log(e)
        }
    }

    const participate = async () => {
        if (isSubmitting) return;

        setIsSubmitting(true);

        if (localStorage.getItem('token') === null) {
            return navigate('/login');
        }
        try {
            let result1 = await SessionDAO.getConflictingSessions(eventId);
            console.log(result1);
            if (result1.length > 0) {
                let confirm = await swal.fire({
                    title: "Warning",
                    html: "This event has a conflicting schedule with an event you have registered. Are you sure you want to proceed?",
                    icon: 'warning',
                    showCancelButton: true,
                    showDenyButton: true,
                    confirmButtonText: 'Continue',
                    denyButtonText: 'Show Conflicting Session',
                    cancelButtonText: 'Cancel',
                    customClass: {
                        actions: 'vertical-buttons',
                        denyButton: 'swal2-cancel'
                    }
                });
                if (confirm.isDenied)
                    return navigate(`/event/${result1[0].id}`);
                if (confirm.isDismissed) {
                    await setIsSubmitting(false);
                    return;
                }
            }
            let result2 = await ParticipantDAO.participate(eventId);
            if (result2.status === "WAITING") {
                await swal.fire({
                    title: "Registration successful.",
                    text: "Please complete the payment process to continue.",
                    icon: "info",
                    confirmButtonText: "Ok"
                })
                window.open(result2.transaction.snap_payment_url, '_blank');
            }
            window.location.reload();
        } catch (e){
            console.log(e);
        }
    }

    const redirect = async () => {
        if (sessionData.participantStatus.status === "REJECTED")
            return await participate();
        return window.open(sessionData.transaction.snap_payment_url, '_blank');
    }

    useEffect(()=>{
        getData();
        setIsSubmitting(false);
    },[eventId])

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <AppContainer title={!isDataLoading ? sessionData.name : null}>
            <Container style={{maxWidth: 1200}}>
                {isDataLoading ?
                    <div style={{
                        width: "100%",
                        padding: 0,
                        margin: 0,
                        marginTop: "4em",
                        marginBottom: "4em",
                        textAlign: "center"
                    }}>
                        <LoadingProgress/>
                    </div> :
                    <Row style={{
                        width: "100%",
                        padding: 0,
                        margin: 0,
                        marginBottom: "4em"
                    }}>
                        <Col style={{
                            textAlign: "center",
                            marginTop: "0.5em",
                            marginBottom: "0.5em"
                        }} md={5}>

                            <div style={{
                                background: "white",
                                paddingTop: 50,
                                paddingBottom: 50,
                                // display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                paddingLeft: "10%",
                                paddingRight: "10%",
                            }}>
                                {sessionData.images.length > 0 ?
                                    <Slider {...carouselSettings} style={{boxShadow: '10px 10px 0px 0px #282833', marginBottom: 50}}>
                                        {sessionData.images.map(link => (
                                            <img alt="session-image" style={{
                                                width: "100%",
                                                objectFit: "contain",
                                            }}
                                                 src={link}/>
                                        ))}
                                    </Slider>
                                    :
                                    <img alt="session-image" style={{
                                        width: "100%",
                                        objectFit: "contain",
                                        boxShadow: '10px 10px 0px 0px #282833'
                                    }}
                                         src={(sessionData.image_url) ? sessionData.image_url : require('../assets/temp_banner/TEMPORARY-POSTER.jpg')}/>
                                }

                                <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                    {sessionData.categories?.map(obj => (
                                        <div style={{marginLeft: 5, marginRight: 5, marginTop: 10}}>
                                            {CategoryIcons.renderBadge(windowWidth, obj.name, 200)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                        <Col style={{
                            textAlign: "center",
                            marginTop: "0.5em",
                            alignItems: "center",
                            justifyContent: "center"
                        }} md={7}>
                            <Row>
                                <Row style={{
                                    paddingTop: 50,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    // paddingLeft: "10%",
                                    // paddingRight: "10%",
                                    width: "100%",
                                    height: "100%",
                                    textAlign: "left"
                                }}>
                                    <Row>
                                        <Col xs={6} sm={4}>
                                            <div style={{
                                                backgroundColor: Palette.PRIMARY,
                                                paddingTop: 10,
                                                paddingBottom: 10,
                                                borderRadius: 5,
                                                color: "white",
                                                width: "100%",
                                                paddingLeft: 15,
                                                paddingRight: 15,
                                                marginBottom: 10,
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center"
                                                }}>
                                                    <FaCalendar/>
                                                    <b style={{marginLeft: 5}}>Date</b>
                                                </div>
                                                {moment(sessionData.start_time).format("DD MMM YYYY")}
                                            </div>
                                        </Col>
                                        <Col xs={6} sm={4}>
                                            <div style={{
                                                backgroundColor: Palette.PRIMARY,
                                                paddingTop: 10,
                                                paddingBottom: 10,
                                                borderRadius: 5,
                                                color: "white",
                                                width: "100%",
                                                paddingLeft: 15,
                                                paddingRight: 15,
                                                marginBottom: 10,
                                                wordWrap: "normal"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center"
                                                }}>
                                                    <FaClock/>
                                                    <b style={{marginLeft: 5}}>Time</b>
                                                </div>
                                                {moment(sessionData.start_time).format("HH:mm")}
                                                 -
                                                {moment(sessionData.end_time).format("HH:mm")}
                                            </div>
                                        </Col>
                                        <Col xs={6} sm={4}>
                                            <div style={{
                                                backgroundColor: Palette.PRIMARY,
                                                paddingTop: 10,
                                                paddingBottom: 10,
                                                borderRadius: 5,
                                                color: "white",
                                                width: "100%",
                                                paddingLeft: 15,
                                                paddingRight: 15,
                                                marginBottom: 10
                                            }}
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center"
                                                }}>
                                                    <FaMapPin/>
                                                    <b style={{marginLeft: 5}}>Location</b>
                                                </div>
                                                {sessionData.location}
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12}>
                                            <p style={{
                                                color: "gray"
                                            }}>
                                                <div style={{
                                                    fontWeight: "bold",
                                                    fontSize: "2em",
                                                    marginTop: 30,
                                                }}>
                                                    {sessionData.name}
                                                </div>
                                                <br/>
                                                <div style={{whiteSpace: "pre-line"}}>
                                                    {sessionData.description}
                                                </div>

                                                <br/>
                                                {sessionData.speakers.length > 0 && <b>Speakers : </b>}
                                                <Row style={{marginTop: 10}}>
                                                    {
                                                        sessionData.speakers && sessionData.speakers.map(obj => {
                                                            return <>
                                                                <Col
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: windowWidth < 600 ? "row" : "row",
                                                                        alignItems: "center", paddingBottom: 10,
                                                                        textAlign: windowWidth < 600 ? 'start' : 'start',
                                                                        textDecoration: "none",
                                                                    }}
                                                                    lg={4} xs={6} md={4}
                                                                >
                                                                    <Link
                                                                        style={{
                                                                            textDecoration: "none",
                                                                            color: "#212529"
                                                                        }}
                                                                        to={"/speaker/" + obj.id}>
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                flexDirection: windowWidth < 600 ? "row" : "row",
                                                                                textAlign: windowWidth < 600 ? 'start' : 'start',
                                                                                alignItems: windowWidth < 600 ? 'center' : 'center',
                                                                            }}
                                                                        >
                                                                            <img
                                                                                alt="speaker-photo"
                                                                                style={{
                                                                                    width: 60,
                                                                                    height: 60,
                                                                                    borderRadius: 60,
                                                                                    objectFit: "cover",
                                                                                    border: `solid 2px ${Palette.SECONDARY}`,
                                                                                    marginRight: windowWidth < 600 ? undefined : 10
                                                                                }}
                                                                                src={obj.photo ? obj.photo : "https://wellsource.sgp1.digitaloceanspaces.com/jaktent/uploads/file/1666955370768-file.png"}/>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                flex: 1,
                                                                                fontWeight: 400,
                                                                                fontSize: "0.8em"
                                                                            }}>
                                                                                <b>{obj.name}</b>
                                                                                <div
                                                                                    style={{fontSize: "0.8em"}}>{obj.title}</div>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </Col>
                                                            </>
                                                        })
                                                    }
                                                </Row>

                                            </p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        {/* <Col md={12}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: 'flex-end'
                                            }}
                                            >
                                                {(sessionData.participantStatus !== null) ?
                                                    <div
                                                        style={{
                                                            maxWidth: 250,
                                                            fontWeight: 700,
                                                            backgroundColor: sessionData.participantStatus.status === "ACCEPTED" ? Palette.AGENDA_GREEN : 'orange',
                                                            cursor: (sessionData.participantStatus.status === "WAITING" || sessionData.participantStatus.status === "REJECTED") ? 'pointer' : 'default',
                                                            userSelect: 'none',
                                                            color: 'white',
                                                            fontSize: 16,
                                                            padding: '15px 15px 15px 15px',
                                                            marginTop: 10,
                                                            paddingLeft: 20,
                                                            textAlign: "center",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            borderRadius: 100
                                                        }} onClick={(sessionData.participantStatus.status === "WAITING" || sessionData.participantStatus.status === "REJECTED") && redirect}>
                                                        <div style={{textAlign: "left"}}>
                                                            {sessionData.participantStatus.status === "PENDING" ? "IN QUEUE" : sessionData.participantStatus.status}
                                                            <br/>
                                                            <div style={{fontSize: "0.7em"}}>
                                                                {sessionData.participantStatus.status === "ACCEPTED" && "You are registered to this event."}
                                                                {sessionData.participantStatus.status === "WAITING" && "Please complete the payment process."}
                                                                {sessionData.participantStatus.status === "PENDING" && "Pending for confirmation."}
                                                                {sessionData.participantStatus.status === "REJECTED" && "Payment expired. Click to register again."}
                                                            </div>
                                                        </div>

                                                        {sessionData.participantStatus.status === "ACCEPTED" &&
                                                            <FiCheck style={{marginLeft: 6}} size={20}/>}
                                                        {(sessionData.participantStatus.status === "REJECTED" && !isSubmitting) &&
                                                            <FiArrowRight style={{marginLeft: 6}} size={20}/>}
                                                        {(sessionData.participantStatus.status === "REJECTED" && isSubmitting) &&
                                                            <Spinner style={{marginLeft: 6, width: 20, height: 20}} animation='border'/>}
                                                        {sessionData.participantStatus.status === "WAITING" &&
                                                            <Spinner style={{marginLeft: 6, width: 20, height: 20}} animation='border'/>}
                                                    </div> :
                                                    <div style={{}}>
                                                        <div
                                                            onClick={participate}
                                                            onMouseOver={() => setBtnHovered(true)}
                                                            onMouseLeave={() => setBtnHovered(false)}
                                                            style={{
                                                                maxWidth: 250,
                                                                fontWeight: 700,
                                                                backgroundColor: isBtnHovered ? Palette.PRIMARY : 'transparent',
                                                                border: `1px solid ${Palette.PRIMARY}`,
                                                                cursor: 'pointer',
                                                                userSelect: 'none',
                                                                color: isBtnHovered ? 'white' : Palette.PRIMARY,
                                                                fontSize: 16,
                                                                padding: '15px 15px 15px 15px',
                                                                marginTop: 10,
                                                                paddingLeft: 20,
                                                                textAlign: "center",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                borderRadius: 100
                                                            }}>
                                                            <div style={{textAlign: "left"}}>
                                                                PARTICIPATE<br/>
                                                                <div style={{fontSize: "0.7em"}}>
                                                                    Price
                                                                    : {(sessionData.price && parseInt(sessionData.price) !== 0) ? ("Rp" + Utils.moneyFormatter(sessionData.price)) : "Free"}
                                                                </div>
                                                            </div>

                                                            {isSubmitting ?
                                                                <Spinner animation='border'
                                                                         style={{marginLeft: 6, width: 20, height: 20}}/> :
                                                                <FiArrowRight style={{marginLeft: 6}} size={20}/>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </Col> */}
                                        <Col md={12}>
                                            {(sessionData.participantStatus === null && parseInt(sessionData.price) !== 0 && sessionData.quota !== 0) &&
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: 'flex-end'
                                                }}>
                                                    <div style={{whiteSpace: "pre-line", textAlign: "right", marginTop: 10}}>
                                                        {sessionData.quota} total slots available.
                                                    </div>
                                                </div>
                                                }
                                        </Col>
                                        <Col md={12}>
                                            {sessionData.participantStatus?.status === "ACCEPTED" &&
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: 'flex-end'
                                                }}>
                                                    <div onClick={() => setIsQRModalOpen(true)} style={{
                                                        maxWidth: 250,
                                                        fontWeight: 700,
                                                        backgroundColor: isBtnHovered ? Palette.PRIMARY : 'transparent',
                                                        border: `1px solid ${Palette.PRIMARY}`,
                                                        cursor: 'pointer',
                                                        userSelect: 'none',
                                                        color: isBtnHovered ? 'white' : Palette.PRIMARY,
                                                        fontSize: 16,
                                                        padding: '15px 15px 15px 15px',
                                                        marginTop: 10,
                                                        paddingLeft: 20,
                                                        textAlign: "center",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        borderRadius: 100,
                                                    }} onMouseOver={() => setBtnHovered(true)} onMouseLeave={() => setBtnHovered(false)}>
                                                        <div style={{textAlign: "left"}}>
                                                            SHOW QR CODE
                                                            <div style={{fontSize: "0.7em"}}>
                                                                Show this QR code on entrance.
                                                            </div>
                                                        </div>
                                                        <FiArrowRight style={{marginLeft: 6}} size={20}/>
                                                    </div>
                                                </div>
                                            }
                                        </Col>
                                        <Col md={12}>
                                            {(sessionData.streaming_url !== "" && moment(sessionData.start_time).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD")) &&
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: 'flex-end'
                                                }}>
                                                    <div onClick={() => {
                                                        setIsYouTubeModalOpen(true);
                                                        setEmbedLink(sessionData.streaming_url.replace("watch?v=", "embed/"))
                                                    }} style={{
                                                        maxWidth: 250,
                                                        fontWeight: 700,
                                                        backgroundColor: Palette.YELLOW,
                                                        cursor: 'pointer',
                                                        userSelect: 'none',
                                                        color: Palette.DARK_GRAY,
                                                        fontSize: 16,
                                                        padding: '15px 15px 15px 15px',
                                                        marginTop: 10,
                                                        paddingLeft: 20,
                                                        textAlign: "center",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        borderRadius: 100,
                                                    }}>
                                                        <div style={{textAlign: "left"}}>
                                                            WATCH STREAM
                                                            <div style={{fontSize: "0.7em"}}>
                                                                Click to watch the live stream.
                                                            </div>
                                                        </div>
                                                        <FiArrowRight style={{marginLeft: 6}} size={20}/>
                                                    </div>
                                                </div>
                                            }
                                        </Col>
                                        <Col md={12}>
                                            {(sessionData.recording_url !== "") &&
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: 'flex-end'
                                                }}>
                                                    <div onClick={() => {
                                                        setIsYouTubeModalOpen(true);
                                                        setEmbedLink(sessionData.recording_url.replace("watch?v=", "embed/"))
                                                    }}  style={{
                                                        maxWidth: 250,
                                                        fontWeight: 700,
                                                        backgroundColor: Palette.YELLOW,
                                                        cursor: 'pointer',
                                                        userSelect: 'none',
                                                        color: Palette.DARK_GRAY,
                                                        fontSize: 16,
                                                        padding: '15px 15px 15px 15px',
                                                        marginTop: 10,
                                                        paddingLeft: 20,
                                                        textAlign: "center",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        borderRadius: 100,
                                                    }}>
                                                        <div style={{textAlign: "left"}}>
                                                            WATCH RECORDING
                                                            <div style={{fontSize: "0.7em"}}>
                                                                Click to watch past recordings.
                                                            </div>
                                                        </div>
                                                        <FiArrowRight style={{marginLeft: 6}} size={20}/>
                                                    </div>
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </Row>

                            </Row>
                        </Col>
                    </Row>
                }
            </Container>

            {(!isDataLoading && sessionData.participantStatus !== null) &&
                <Modal show={isQRModalOpen} fullscreen centered onClick={() => setIsQRModalOpen(false)} size={"lg"}>
                    <Modal.Body style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                        <p style={{textAlign: "center", fontSize: "1.2em"}}>{sessionData.name}</p>
                        <p style={{textAlign: "center", fontSize: "1.2em"}}>{localStorage.getItem("name")}</p>
                        <br />
                        <QRCode size={512} viewBox={`0 0 512 512`} style={{maxWidth: '75%', height: 'auto'}} value={`${process.env.REACT_APP_ADMIN_URL}#/attendance?attending=1&participantId=${sessionData.participantStatus.id}`} />
                        <br />
                        <p style={{textAlign: "center", fontSize: "1.2em"}}>Show this QR code to the committee before joining the session.</p>
                        <p style={{textAlign: "center", fontSize: "1em", color: Palette.RED, fontWeight: "bold"}}>Tap/click on the screen to exit.</p>
                    </Modal.Body>
                </Modal>
            }

            {!isDataLoading &&
                <Modal show={isYouTubeModalOpen} size="xl" centered onHide={() => {
                    setIsYouTubeModalOpen(false);
                    setEmbedLink(null);
                }}>
                    <Modal.Header closeButton />
                    <Modal.Body style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", height: windowWidth >= 1200 ? 700 : windowWidth > 991 ? 500 : 300}}>
                        <iframe src={embedLink} width="100%" height="100%"/>
                    </Modal.Body>
                </Modal>
            }
        </AppContainer>
    )
}
