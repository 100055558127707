import AppContainer from "../components/AppContainer";
import React, {useEffect, useState} from "react";
import {Col, Container, Row, Form, InputGroup} from "react-bootstrap";
import {
    AiOutlineLock,
    AiOutlineMail,
    AiOutlineUser,
    AiOutlineHome,
    AiOutlineGlobal,
    AiOutlineBulb, AiOutlinePhone
} from "react-icons/ai";
import Palette from "../utils/Palette";
import moment from "moment";
import {BiCake, BiGame, BiJoystick, BiPhone, BiSortUp} from "react-icons/bi";
import LoadingProgress from "../components/LoadingProgress";
import UserDAO from "../dao/UserDAO";
import swal from "../components/CustomSweetAlert";
import {useNavigate} from "react-router-dom";
import Utils from "../utils/Utils";

import countries from "../utils/Countries";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css'

const INTERESTS = [
    "Book & Literature",
    "Music",
    "Film",
    "Culinary",
    "IP, Game & Cosplay",
    "Fashion",
    "Architecture & Design",
]

export default function Register() {

    const navigate = useNavigate();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [country, setCountry] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [interests, setInterests] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorFields, setErrorFields] = useState({
        nameError: '',
        emailError: '',
        passwordError: '',
        confPasswordError: '',
        dobError: '',
        countryError: '',
        phoneNumberError: '',
        miscError: '',
    });

    const changeInterest =  (int) =>{
        if(interests.includes(int))
            setInterests(interests.filter(i=>i !== int))
        else
            setInterests([...interests, int])
    }


    const register = async () => {
        if (isSubmitting) return;

        await setIsSubmitting(true);
        let errors = validate();
        if (Object.keys(errors).length !== 0) {
            setErrorFields(errors);
            await setIsSubmitting(false);
            for (const obj in errors) {
                await swal.fire({
                    title: "Error",
                    text: errors[obj],
                    icon: "error",
                    confirmButtonText: "Ok"
                });
                break;
            }
            return;
        }
        setErrorFields({});

        let body = {
            full_name: fullName,
            email: email,
            password: password,
            birth_date: dateOfBirth,
            country: country,
            phone_number: phoneNumber,
            creative_interest: interests
        }
        try {
            let response = await UserDAO.register(body);
            console.log(response);
            await swal.fire({
                title: 'Success',
                text: 'You have successfully registered!',
                icon: 'success',
                confirmButtonText: 'Ok'
            })
            setIsSubmitting(false);
            navigate('/login');
        } catch (e) {
            if (e.code === "DUPLICATE_EMAIL")
                errors.emailError = "This e-mail address has already been used."
            else {
                errors.miscError = "An error has occurred. Please try again later.";
                console.log(e.error_message);
            }
            setErrorFields(errors);
            setIsSubmitting(false);
        }
    }
    const validate = () => {
        let temp = {};
        if (fullName === "")
            temp.nameError = 'Please fill in your full name.'
        if (email === "")
            temp.emailError = 'Please fill in your e-mail address.'
        else if (!Utils.validateEmail(email))
            temp.emailError = 'Please enter a valid e-mail address.'
        if (password === "")
            temp.passwordError = 'Please fill in a new password.'
        if (confirmPassword === "")
            temp.confPasswordError = 'Please fill in your password again.'
        else if (password !== confirmPassword)
            temp.confPasswordError = 'Password does not match. Try again.'
        if (dateOfBirth === "")
            temp.dobError = 'Please select your date of birth.'
        if (country === "")
            temp.countryError = 'Please select your country.'
        if (phoneNumber.length < 7)
            temp.phoneNumberError = 'Please enter your phone number.'
        return temp;
    }

    const onEnter = async (e) => {
        if (e.key === "Enter") await register();
    }

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <AppContainer title={"Register"}>
            <div style={{
                fontFamily: 'anton',
                fontSize: windowWidth > 768 ? 80 : 40,
                transition: 'font-size .5s',
                textAlign: 'center',
                marginTop: 50,
                marginBottom: 20
            }}>
                REGISTER
            </div>

            <Container>
                <Row>
                    <Col style={{marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                        <div style={{backgroundColor: 'red', position: 'relative'}}>
                            <AiOutlineUser size={20} style={{position: 'absolute', left: 25, top: 0, bottom: 0, margin: 'auto 0'}}/>

                            <input type="text" placeholder="Full Name" required="" style={{
                                fontSize: 20,
                                border: '3px solid black',
                                padding: '15px 10px 15px 70px',
                                boxShadow: '10px 10px 0px 0px #282833',
                                width: windowWidth > 768 ? 500 : 'calc(100vw - 60px)',
                            }} value={fullName} onKeyDown={onEnter} onChange={(e) => setFullName(e.target.value)} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col style={{display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 25}}>
                        <p style={{color: "rgb(247, 91, 87)"}}>{errorFields.nameError}</p>
                    </Col>
                </Row>

                <Row>
                    <Col style={{marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                        <div style={{backgroundColor: 'red', position: 'relative'}}>
                            <AiOutlineMail size={20} style={{position: 'absolute', left: 25, top: 0, bottom: 0, margin: 'auto 0'}}/>

                            <input type="email" placeholder="E-mail" required="" style={{
                                fontSize: 20,
                                border: '3px solid black',
                                padding: '15px 10px 15px 70px',
                                boxShadow: '10px 10px 0px 0px #282833',
                                width: windowWidth > 768 ? 500 : 'calc(100vw - 60px)',
                            }} value={email} onKeyDown={onEnter} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col style={{display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 25}}>
                        <p style={{color: "rgb(247, 91, 87)"}}>{errorFields.emailError}</p>
                    </Col>
                </Row>

                <Row>
                    <Col style={{marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                        <div style={{backgroundColor: 'red', position: 'relative'}}>
                            <AiOutlineLock size={20} style={{position: 'absolute', left: 25, top: 0, bottom: 0, margin: 'auto 0'}}/>

                            <input type="password" placeholder="Password" required="" style={{
                                fontSize: 20,
                                border: '3px solid black',
                                padding: '15px 10px 15px 70px',
                                boxShadow: '10px 10px 0px 0px #282833',
                                width: windowWidth > 768 ? 500 : 'calc(100vw - 60px)',
                            }} value={password} onKeyDown={onEnter} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col style={{display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 25}}>
                        <p style={{color: "rgb(247, 91, 87)"}}>{errorFields.passwordError}</p>
                    </Col>
                </Row>

                <Row>
                    <Col style={{marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                        <div style={{backgroundColor: 'red', position: 'relative'}}>
                            <AiOutlineLock size={20} style={{position: 'absolute', left: 25, top: 0, bottom: 0, margin: 'auto 0'}}/>

                            <input type="password" placeholder="Confirm Password" required="" style={{
                                fontSize: 20,
                                border: '3px solid black',
                                padding: '15px 10px 15px 70px',
                                boxShadow: '10px 10px 0px 0px #282833',
                                width: windowWidth > 768 ? 500 : 'calc(100vw - 60px)',
                            }} value={confirmPassword} onKeyDown={onEnter} onChange={(e) => setConfirmPassword(e.target.value)} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col style={{display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 25}}>
                        <p style={{color: "rgb(247, 91, 87)"}}>{errorFields.confPasswordError}</p>
                    </Col>
                </Row>
                <Row>
                    <Col style={{marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                        <div style={{backgroundColor: 'red', position: 'relative'}}>
                            <BiCake size={20} style={{position: 'absolute', left: 25, top: 0, bottom: 0, margin: 'auto 0'}}/>

                            <input type="date" placeholder="Birth Date" required="" max={moment().format('YYYY-MM-DD')} style={{
                                fontSize: 20,
                                border: '3px solid black',
                                padding: '15px 10px 15px 70px',
                                boxShadow: '10px 10px 0px 0px #282833',
                                width: windowWidth > 768 ? 500 : 'calc(100vw - 60px)',
                            }} value={dateOfBirth} onKeyDown={onEnter} onChange={(e) => setDateOfBirth(e.target.value)} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col style={{display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                        <p style={{color: "rgb(247, 91, 87)"}}>{errorFields.dobError}</p>
                    </Col>
                </Row>
                <Row>
                    <Col style={{marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                        <div style={{backgroundColor: 'red', position: 'relative'}}>
                            <AiOutlineGlobal size={20} style={{position: 'absolute', left: 25, top: 0, bottom: 0, margin: 'auto 0'}}/>

                            <Form.Select name="country" style={{
                                borderRadius: 0,
                                fontSize: 20,
                                border: '3px solid black',
                                padding: '15px 10px 15px 70px',
                                boxShadow: '10px 10px 0px 0px #282833',
                                width: windowWidth > 768 ? 500 : 'calc(100vw - 60px)',
                            }} value={country} onChange={(e) => setCountry(e.target.value)}>
                                <option value="" selected disabled>Country</option>
                                <option value={"Indonesia"}>Indonesia</option>
                                <option value="" disabled>-------------------------</option>
                                {countries.map(obj => (
                                    <option style={{
                                        padding: 0
                                    }} value={obj.country}>{obj.country}</option>
                                ))}
                            </Form.Select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col style={{display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                        <p style={{color: "rgb(247, 91, 87)"}}>{errorFields.countryError}</p>
                    </Col>
                </Row>

                <Row>
                    <Col style={{marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                        <div style={{backgroundColor: 'white', position: 'relative'}}>
                            <AiOutlinePhone size={20} style={{position: 'absolute', left: 25, top: 0, bottom: 0, margin: 'auto 0', zIndex: 2}}/>
                            <PhoneInput
                                placeholder="Phone Number"
                                countryCodeEditable={false}
                                autoFormat={false}
                                country={'id'}
                                preferredCountries={['id']}
                                preserveOrder={['preferredCountries']}
                                disableDropdown={false}
                                value={phoneNumber} onChange={(e) => setPhoneNumber(e)}
                                inputStyle={{
                                    borderRadius: 0,
                                    fontFamily: 'Interstate',
                                    fontSize: 20,
                                    border: '3px solid black',
                                    padding: '15px 10px 15px 130px',
                                    boxShadow: '10px 10px 0px 0px #282833',
                                    width: windowWidth > 768 ? 500 : 'calc(100vw - 60px)',
                                }}
                                buttonStyle={{margin: '15px 0 15px 70px', backgroundColor: Palette.LIGHT_GRAY, backgroundClip: 'border-box', borderRadius: 5}}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col style={{marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                        <p style={{color: "rgb(247, 91, 87)"}}>{errorFields.phoneNumberError}</p>
                    </Col>
                </Row>
                <Row>
                    <Col style={{marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                        <div style={{position: 'relative'}}>
                            <AiOutlineBulb size={30} style={{position: 'absolute', left: -130, top: 0, bottom: 270, margin: 'auto 0'}}/>
                            <div style={{position: 'absolute', left: -90, top: 0, bottom: 0, margin: 'auto 0'}}>Interests</div>
                            <Form>
                                {INTERESTS.map((interest) => (
                                    <div key={`inline-${interest}`} className="mb-3">
                                      <Form.Check
                                        inline
                                        label={interest}
                                        name={interest+'-name'}
                                        type="checkbox"
                                        id={interest+'-id'}
                                        checked={interests.includes(interest)}
                                        onClick={()=>changeInterest(interest)}
                                      />
                                    </div>
                                ))}
                            </Form>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col style={{marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                        <p style={{color: "rgb(247, 91, 87)"}}>{errorFields.miscError}</p>
                    </Col>
                </Row>
                
                <Row>
                    <Col style={{marginBottom: 20, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                        <div style={{position: 'relative'}}>
                            <div style={{
                                fontWeight: 700,
                                backgroundColor: Palette.PRIMARY,
                                cursor: 'pointer',
                                userSelect: 'none',
                                color: 'white',
                                fontSize: 16,
                                padding: '15px 45px 15px 45px',
                                left: 0,
                                right: 0,
                                textAlign: 'center',
                                bottom: -100,
                                width: windowWidth > 450 ? 370 : 'calc(100vw - 60px)'
                            }} onClick={register}>
                                {isSubmitting ? <LoadingProgress /> : "REGISTER"}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col style={{marginBottom: 100, display: 'flex', justifyContent: 'center', paddingLeft: 35, paddingRight: 35}}>
                        <p style={{color: "rgb(247, 91, 87)"}}>{errorFields.miscError}</p>
                    </Col>
                </Row>
            </Container>
        </AppContainer>
    )
}
